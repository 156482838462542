var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.isRendered = false;
        this.xAxisYears = [];
        this.selectedParams = {
            "ventes":[],
            "types":[],
            "vasetypes":[],
            "matieres":[],
            "acteurs":[],
            "idobjet":[],
            "idvente":[]
        };

        this.selectedTransactions = [];

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("routerValeurs");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        if(!this.isRendered){
            this.isRendered = true;
            this.utils.gatherData(function(){
                self.render();
            });
        }
    },

    render:function(){
    	this.utils.fillTranslations();
    	this.headerView.render();
        this.footerView.render();
        this.renderChart();
        this.buildOptions();
    	this.bindEvents();
        this.update();
        $("#chartMethodoBtn").attr("data-text",App.utils.translations["comment-lire"][App.selectedLang])
    },

    renderChart:function(){
        var self = this;

        this.objetVentes = {};
        this.categoriesInt = [];
        _.each(App.utils.ventes, function(v){
            if(v.ventes_prix_value !== null && v.ventes_prix_value > 0 && !isNaN(v.vente_date_ts)){
                if(self.objetVentes[v.id_objet] == undefined){
                    self.objetVentes[v.id_objet] = {
                        id:v.id_objet,
                        ventes:{}
                    };
                }

                self.objetVentes[v.id_objet].ventes[String(v.vente_date_ts)] = v.ventes_prix_value;
                self.categoriesInt.push(v.vente_date_ts);
            }
        });

        this.categoriesInt = _.uniq(this.categoriesInt);
        this.sortedCategories = _.sortBy(this.categoriesInt, function(c){ return c;});
        this.categories = [];

        _.each(this.sortedCategories, function(c){
            self.categories.push(String(c));
        })

        var maxYear = moment.unix(this.categories[this.categories.length-1]).format("YYYY");
        var minYear = moment.unix(this.categories[0]).format("YYYY");
        $(".dateBornesChart[data-borne='new']").html(maxYear)
        $(".dateBornesChart[data-borne='old']").html(minYear)

        this.seriesData = [];
        _.each(this.objetVentes, function(o){
            var categVentes = [];
            _.each(self.categories, function(c){
                if(o.ventes[c] === undefined){
                    categVentes.push([c, null]);
                }else{
                    categVentes.push([c, o.ventes[c]])
                }
            });
            self.seriesData.push({
                data:categVentes,
                id:o.id,
                name:o.id,
                color:"#333"
            });
        });

        this.myHighchart = new Highcharts.chart({
            chart: {
                renderTo:"MyChart",
                type: 'scatter',
                width:window.innerWidth-300,
                height:window.innerHeight-200,
                zoomType: 'xy',
                backgroundColor:"transparent",
                spacing:[50,0,20,0],
                events:{
                    selection:function(evt){
                        self.xAxisYears = [];
                    }
                }
            },
            title: {
                text: ''
            },
            xAxis:{
                categories:this.categories,
                labels:{
                    enabled:true,
                    distance:15,
                    useHTML:true,
                    autoRotation:false,
                    style:{
                        fontSize:"11px",
                    },
                    formatter:function(){
                        var year = moment.unix(parseInt(this.value, 10)).format("YYYY");
                        console.log("formatter ???", self.xAxisYears, year);
                        if(_.indexOf(self.xAxisYears, year) == -1){
                            self.xAxisYears.push(year);
                            console.log("has value ?", year);
                            return year;
                        }else{
                            return "";
                        }
                        
                    }
                },
                lineColor: '#e0d9ca',
                tickLength:0,
                events:{
                    afterSetExtremes:function(){
                        $("#zoomResetBtn").addClass("show");
                    }
                },
            },
            yAxis:{
                type:'logarithmic',
                min:1,
                minorTickInterval: 1,
                title:{
                    text:""
                },
                labels:{
                    formatter:function(){
                        if(this.isLast){
                            return _.str.numberFormat(this.value, 0, ".", " ")+"<br>Francs"
                        }
                        return _.str.numberFormat(this.value, 0, ".", " ");
                        
                    }
                }
            },
            plotOptions:{
                series:{
                    lineWidth:1,
                    cursor:"pointer",
                    lineColor:"white",
                    connectNulls:true,
                    states:{
                        hover:{
                        }
                    },
                    marker:{
                        symbol:"circle",
                        fillColor:"#aaa",
                        lineColor:"#aaa",
                    },
                    point:{
                        events:{
                            click:function(){
                                App.selectedParams.idobjet = [];
                                App.selectedParams.idobjet.push(this.series.name);
                                //App.update();
                                self.buildTooltip(this.name,this.y,App.utils.objets[this.series.name]);
                            }
                        }
                    },
                    events:{
                        mouseOver:function(){
                            var $line = $(".highcharts-series-"+this.index+" .highcharts-graph");
                            if($line.attr("data-filtred")=="hide"){
                                $line.attr("data-stroke", "grey"); // CLEM
                            }else{
                                $line.attr("data-stroke", "red");    
                            }
                        },
                        mouseOut:function(){
                            var $line = $(".highcharts-series-"+this.index+" .highcharts-graph");
                            if($line.attr("data-selected")!="selected"){
                                $line.attr("data-stroke", "white");
                            }
                        },
                    },
                }
            },
            legend:{
                enabled:false
            },
            credits:{
                enabled:false
            },
            tooltip: {
                backgroundColor:"#1d1d1b",
                useHTML:true,
                formatter:function(){
                    var html = self.countPoint(this.x, this.y, this.series.name);
                    return html;
                }
            },
            series:  App.seriesData,
        });

    },

    countPoint:function(xDate, yPrice, obj){
        var self = this;
        var correspondingVentes = _.filter(App.utils.ventes, function(v){
            return v.vente_date_ts == parseInt(xDate, 10) && v.ventes_prix_value == parseInt(yPrice, 10);
        });
        if(correspondingVentes.length>1){
            return "<div class='resultTooltip'><span>"+correspondingVentes.length+" objets</span></div>";    
        }else{
            return false;
        }
    },

    buildTooltip:function(xDate, yPrice, obj){
        var self = this;
        $(".modaleRotateBtn").unbind("click");
        $("#graphTransactionModale").addClass("show");
        var correspondingVentes = _.filter(App.utils.ventes, function(v){
            return v.vente_date_ts == parseInt(xDate, 10) && v.ventes_prix_value == parseInt(yPrice, 10);
        });
        $(".modaleRotateBtn").removeClass("show");
        if(correspondingVentes.length>1) $(".modaleRotateBtn").addClass("show");
        var currentDisplayVente = 0
        
        _.each(correspondingVentes,function(venteSelect,i){
            if(venteSelect.id_objet==obj.id_objet) {
                self.insertInformationInTooltip(correspondingVentes[i]);
                currentDisplayVente = i;
            }
        });
        
        $(".modaleRotateBtn").on("click",function(){
            var direction = $(this).attr("data-rotate");
            if(direction == "prev"){
                currentDisplayVente=currentDisplayVente-1;
                if(currentDisplayVente==-1){
                    currentDisplayVente=correspondingVentes.length-1;
                }  
            }else{
                currentDisplayVente=currentDisplayVente+1;
                if(currentDisplayVente==correspondingVentes.length){
                    currentDisplayVente=0;
                }  
            }
            self.insertInformationInTooltip(correspondingVentes[currentDisplayVente]);
        });

        
        $(".highcharts-series .highcharts-graph").attr("data-stroke", "white");
        $(".highcharts-series .highcharts-graph").attr("data-selected", "");

        _.each(App.myHighchart.series,function(s,i){
            _.each(s.points,function(p,j){
                p.setState("");
            });
        });

        _.each(App.myHighchart.series,function(serie,i){
            if(serie.name == obj.id_objet){
                var findXPoint = _.find(serie.points, function(p){ return p.name == xDate.toString(); });
                findXPoint.setState("hover");
                var $line = $(".highcharts-series-"+serie.index+" .highcharts-graph");
                $line.attr("data-selected","selected");
                if($line.attr("data-filtred")=="hide"){
                    $line.attr("data-stroke", "grey"); // CLEM
                }else{
                    $line.attr("data-stroke", "red");    
                }
            }
        });

        var venteObjet = _.find(correspondingVentes, function(v){ return v.id_objet == obj.id_objet; });
        this.selectedParams.idvente = [venteObjet.id_vente];
        this.router.updateRoute();
    },

    insertInformationInTooltip:function(vente){
        console.log(vente);
        var obj = App.utils.objets[vente.id_objet];
        var objImg = "img/collection/UK_"+vente.id_objet+".jpg";
        App.utils.imageExists(objImg,function(imgExists){
                if(imgExists==true){
                    $("#modaleValeurImg").attr("src","img/collection/UK_"+vente.id_objet+".jpg");
                }else{
                    var defaultImage = "img/collection/default/"+_.str.slugify(obj.categorie_picto)+"-";
                    if(obj.matiere_filtre !== null && obj.matiere_filtre !== ""){
                        defaultImage += (_.str.slugify(obj.matiere_filtre) + ".svg");
                    }else{
                        defaultImage += "defaut.svg";
                    }
                    $("#modaleValeurImg").attr("src",defaultImage);
                }
        });


        $("#modaleValeurTitle").html(obj.nom_objet);
        $("#modaleValeurVenteName").html(vente.vente_nom.replace("Vente ",""));
        $("#modaleValeurVentePrix").html(vente.ventes_prix_francs);
        $("#modaleValeurLink").attr("data-obj",vente.id_objet);

        this.selectedParams.idvente = [vente.id_vente];
        this.selectedParams.idobjet = [vente.id_objet];
        this.router.updateRoute();
    },

    buildOptions:function(){
        var self = this;
        var tabVentes = [];
        var tabTypes = [];
        var tabMatieres = [];
        var tabVases = [];
        var tabActeurs = [];

        _.each(App.utils.ventes,function(vente,i){
            
            if(vente.ventes_prix_value !== null && vente.ventes_prix_value > 0 && !isNaN(vente.vente_date_ts)){

                if(!tabVentes.includes(vente.vente_nom)){
                    tabVentes.push(vente.vente_nom);
                }

                if(!tabTypes.includes(App.utils.objets[vente.id_objet].categorie_picto)){
                    tabTypes.push(App.utils.objets[vente.id_objet].categorie_picto);
                }

                if(!tabMatieres.includes(App.utils.objets[vente.id_objet].matiere_filtre)&&App.utils.objets[vente.id_objet].matiere_filtre!=null){
                    tabMatieres.push(App.utils.objets[vente.id_objet].matiere_filtre);
                }

                if(!tabVases.includes(App.utils.objets[vente.id_objet].type_vase)&&App.utils.objets[vente.id_objet].categorie_picto=="vase"&&App.utils.objets[vente.id_objet].type_vase!=""){
                    tabVases.push(App.utils.objets[vente.id_objet].type_vase);
                }

            }

        });

        var tempTabActeurs=[];
        _.each(App.utils.acteurs,function(acteur,i){
            if(acteur.acteur_star==1){
                tempTabActeurs.push(acteur)
            }
        });
        var tempTabActeurs = _.sortBy(tempTabActeurs, function(a){ return parseInt(a.nb_occ_INHA_ventes);}).reverse();


        _.each(tempTabActeurs,function(acteur,i){
            tabActeurs.push(acteur.uk_acteur)
        })

        tabVentes.sort(function(a,b){
            return  parseInt(a.slice(-4)) - parseInt(b.slice(-4));
        });

        _.each(tabVentes,function(venteName,i){
            var html = "<div class='optionLine'><div class='optionCheckbox' data-option='"+_.str.slugify(venteName)+"'></div>"+venteName.replace("Vente ","")+"</div>";
            $(".filtreOptionsContainer[data-options='ventes']").append(html);
        });

        _.each(tabTypes,function(type,i){
            var html = "<div class='optionLine'><div class='optionCheckbox' data-option='"+_.str.slugify(type)+"'></div>"+type.charAt(0).toUpperCase() + type.slice(1)+"</div>";
            $(".filtreOptionsContainer[data-options='types']").append(html);
        });

        _.each(tabMatieres,function(matiere,i){
            var html = "<div class='optionLine'><div class='optionCheckbox' data-option='"+_.str.slugify(matiere)+"'></div>"+matiere.charAt(0).toUpperCase() + matiere.slice(1)+"</div>";
            $(".filtreOptionsContainer[data-options='matieres']").append(html);
        });
        
        _.each(tabVases,function(typevase,i){
            var html = "<div class='optionLine'><div class='optionCheckbox' data-option='"+_.str.slugify(typevase)+"'></div>"+typevase.charAt(0).toUpperCase() + typevase.slice(1)+"</div>";
            $(".filtreOptionsContainer[data-options='vasetypes']").append(html);
        });

        _.each(tabActeurs,function(acteurID,i){
            var acteurName = App.utils.acteurs[acteurID].acteur_nom;
            var html = "<div class='optionLine'><div class='optionCheckbox' data-option='"+_.str.slugify(acteurID)+"'></div>"+acteurName+"</div>";
            $(".filtreOptionsContainer[data-options='acteurs']").append(html);
        });

    },

    update:function(){
        App.filtredObj = {};
        this.updateMenus();
        this.updateSelectedTransactions();
        this.updateGraph();
        this.updateTooltip();
        this.router.updateRoute(false);
    },

    updateTooltip:function(){
        var self = this;
        if(App.utils.objets[App.selectedParams.idobjet] !== undefined){
            var allVentes = App.utils.objets[App.selectedParams.idobjet].ventes;
            // VOIR ICI LE PARAM ID VENTE AVEC ANTHO
            if(App.selectedParams.idobjet != ""){
                var obj = App.utils.objets[App.selectedParams.idobjet];

                var objDate = "";
                var objPrix = "";
                if(App.selectedParams.idvente.length > 0){
                    console.log(obj);
                    var foundVente = _.find(obj.ventes, function(v){ return v.id_vente == App.selectedParams.idvente[0]; });
                    objDate = foundVente.vente_date_ts;
                    objPrix = foundVente.ventes_prix_value;
                }else{
                    var objDate = obj.ventes[_.keys(obj.ventes)[0]].vente_date_ts;
                    var objPrix = obj.ventes[_.keys(obj.ventes)[0]].ventes_prix_value;
                }
                
                this.buildTooltip(objDate, objPrix, obj);
            }
        }
    },

    updateGraph:function(){
        var self = this;

        var filtres = _.keys(App.filtredObj);
        if(filtres.length==0){
            $(".highcharts-point").attr("data-filtred","default");
            $(".highcharts-series .highcharts-graph").attr("data-filtred","default");
        }else{
            $(".highcharts-point").attr("data-filtred","hide");
            $(".highcharts-series .highcharts-graph").attr("data-filtred","hide");
        }
        _.each(App.seriesData,function(serie,i){
            _.each(App.filtredObj,function(tabOfItem,filtreName){
                if(tabOfItem.includes(serie.id)){
                    var filtreNumber =filtres.indexOf(filtreName);
                    if(App.venteFiltreActivated==true&&App.utils.objets[serie.id].sortedVentes.length>1){
                        var tabVentes = []
                        _.each(App.utils.objets[serie.id].sortedVentes,function(vente){
                            tabVentes.push(vente.vente_nom.replace("Vente ",""));
                        });
                        var venteIndex = tabVentes.indexOf(filtreName);
                        var $secondPoint = $($(".highcharts-markers.highcharts-series-"+i+" .highcharts-point")[venteIndex]);
                        $secondPoint.attr("data-filtred","filtred-"+filtreNumber);
                    } else{
                        $(".highcharts-markers.highcharts-series-"+i+" .highcharts-point").attr("data-filtred","filtred-"+filtreNumber);
                    }
                }    
            });
        });
    },

    updateMenus:function(){
        var self = this;
        $(".optionCheckbox").removeClass("checked");
        if(App.selectedLang=="fr"){
            $(".selectionNumber").html("(toutes)");
            $(".filtreBox[data-filtre='types'] .selectionNumber").html("(tous)");
            $(".filtreBox[data-filtre='acteurs'] .selectionNumber").html("(tous)");
            $(".filtreBox[data-filtre='vasetypes'] .selectionNumber").html("(tous)");
        }else{
            $(".selectionNumber").html("(all)");
            $(".filtreBox[data-filtre='types'] .selectionNumber").html("(all)");
            $(".filtreBox[data-filtre='acteurs'] .selectionNumber").html("(all)");
            $(".filtreBox[data-filtre='vasetypes'] .selectionNumber").html("(all)");
        }
        $(".filtreBoxCancelBtn").removeClass("active");
        $(".filtreBox").removeClass("filtred"); 

        _.each(App.selectedParams,function(tabSelectedOption,filtre){

            if(tabSelectedOption.length>0){
                $(".filtreBox[data-filtre='"+filtre+"'] .selectionNumber").html("("+tabSelectedOption.length+")");
                $(".filtreBox[data-filtre='"+filtre+"']").parents(".filtreBoxContainer").find(".filtreBoxCancelBtn").addClass("active");  
                $(".filtreBox[data-filtre='"+filtre+"']").addClass("filtred");  
            }

            _.each(tabSelectedOption,function(value,i){
                $(".filtreOptionsContainer[data-options='"+filtre+"'] .optionCheckbox[data-option='"+value+"']").addClass("checked");
            })
        })

        if(_.without(App.selectedParams.types, "vase").length==0&&App.selectedParams.types.includes("vase")){
            $(".filtreBox[data-filtre='vasetypes']").addClass("show");
            $(".filtreBox[data-filtre='types']").addClass("vasetypes");
            $("#typesVaseTitle").addClass("show");
        } else{
            $(".filtreBox[data-filtre='vasetypes']").removeClass("show");
            $(".filtreBox[data-filtre='types']").removeClass("vasetypes");
            $("#typesVaseTitle").removeClass("show");
        }
                
    },

    updateSelectedTransactions:function(){

        var self=this;
        
        App.selectedTransactions = [];

        if(App.selectedParams.ventes.length==0&&App.selectedParams.types.length==0&&App.selectedParams.matieres.length==0&&App.selectedParams.acteurs.length==0){
            _.each(App.utils.ventes,function(vente,i){
                if(vente.ventes_prix_value !== null && vente.ventes_prix_value > 0 && !isNaN(vente.vente_date_ts)){
                App.selectedTransactions.push(vente);
                }
            });
        }else{
            _.each(App.selectedParams,function(tabSelectedOption,filtre){
                if(tabSelectedOption.length>0){
                    _.each(App.utils.ventes,function(vente,i){
                        if(vente.ventes_prix_value !== null && vente.ventes_prix_value > 0 && !isNaN(vente.vente_date_ts)){
                            if(filtre == "matieres"){
                                var valueToCheck = _.str.slugify(App.utils.objets[vente.id_objet].matiere_filtre);
                                if(tabSelectedOption.includes(valueToCheck)){
                                    App.selectedTransactions.push(vente);
                                }
                            } else if (filtre == "ventes"){
                                var valueToCheck = _.str.slugify(vente.vente_nom);
                                if(tabSelectedOption.includes(valueToCheck)){
                                    App.selectedTransactions.push(vente);
                                }
                            }else if (filtre=="acteurs"){
                                var acheteurUK = _.str.slugify(vente.uk_acteur_vente_acheteur).toString();
                                var vendeurUK = _.str.slugify(vente.uk_acteur_vente_vendeur).toString();
                                if(tabSelectedOption.includes(acheteurUK)||tabSelectedOption.includes(vendeurUK)){
                                    App.selectedTransactions.push(vente);
                                }
                            } else if (filtre == "types"){
                                if(App.selectedParams.vasetypes.length > 0){
                                    var valueToCheck = _.str.slugify(App.utils.objets[vente.id_objet].type_vase);
                                    if(App.selectedParams.vasetypes.includes(valueToCheck)){
                                        App.selectedTransactions.push(vente);
                                    }
                                }else{  
                                    var valueToCheck = _.str.slugify(App.utils.objets[vente.id_objet].categorie_picto);    
                                    if(tabSelectedOption.includes(valueToCheck)){
                                       App.selectedTransactions.push(vente);
                                    }
                                }
                            }
                        }
                    });
                }
            });
        }

        self.updateDetails();

    },

    updateDetails:function(){
        var self = this;
        App.venteFiltreActivated = false;
        if(App.selectedParams.ventes.length==0&&App.selectedParams.types.length==0&&App.selectedParams.matieres.length==0&&App.selectedParams.acteurs.length==0){
            $(".cancelFilterBtn").removeClass("reveal");
            $("#selectedTransactionsContainer").empty();
            $("#transactionNumber").html(App.selectedTransactions.length);
        }else{
            $("#selectedTransactionsContainer").empty();
            $("#transactionNumber").html(App.selectedTransactions.length);
                
            if(App.selectedParams.ventes.length>0){

                var venteUnique = _.groupBy(App.selectedTransactions,function(vente){
                        return vente.vente_nom
                });

                var categories = {};

                _.each(venteUnique,function(items,venteName){
                    var newName = venteName.replace("Vente ","");
                    var tabId = [];
                    _.each(items,function(item,i){
                        tabId.push(item.id_objet)
                    })
                    categories[newName] = tabId;
                });

                App.venteFiltreActivated = true;
            
            } else if(App.selectedParams.types.length>0){

                if(App.selectedParams.vasetypes.length > 0){
                    proprietyObj = "type_vase";
                } else{
                    proprietyObj = "categorie_picto";
                }

                var typeUnique = {};

                _.each(App.selectedTransactions,function(vente){
                    typeUnique[App.utils.objets[vente.id_objet][proprietyObj]] = [];
                })

                _.each(App.selectedTransactions,function(vente){
                    typeUnique[App.utils.objets[vente.id_objet][proprietyObj]].push(vente.id_objet);
                })

                var categories = {};

                _.each(typeUnique,function(items,typeName){
                    var newName = typeName.charAt(0).toUpperCase() + typeName.slice(1)
                    categories[newName] = items;
                });

            } else if (App.selectedParams.matieres.length>0){

                var typeUnique = {};

                _.each(App.selectedTransactions,function(vente){
                    typeUnique[App.utils.objets[vente.id_objet].matiere_filtre] = [];
                })

                _.each(App.selectedTransactions,function(vente){
                    typeUnique[App.utils.objets[vente.id_objet].matiere_filtre].push(vente.id_objet);
                })

                var categories = {};

                _.each(typeUnique,function(items,typeName){
                    var newName = typeName.charAt(0).toUpperCase() + typeName.slice(1)
                    categories[newName] = items;
                });

            } else if (App.selectedParams.acteurs.length>0){
                    
                var typeUnique = {};

                _.each(App.selectedParams.acteurs,function(selectedActeurs,filtre){
                    typeUnique[selectedActeurs]= [];
                });

                _.each(App.selectedTransactions,function(vente){
                    if(typeof typeUnique[vente.uk_acteur_vente_acheteur] != "undefined"){
                        typeUnique[vente.uk_acteur_vente_acheteur].push(vente.id_objet);
                    }
                    if(typeof typeUnique[vente.uk_acteur_vente_vendeur] != "undefined"){
                        typeUnique[vente.uk_acteur_vente_vendeur].push(vente.id_objet);
                    }
                });

                var tempCategories = {};

                 _.each(typeUnique,function(items,idActeurs){
                    var newName = App.utils.acteurs[idActeurs].acteur_nom;
                    tempCategories[newName] = items;
                });

                keysSorted = Object.keys(tempCategories).sort(function(a,b){return tempCategories[a].length-tempCategories[b].length}).reverse();

                var categories = {}

                _.each(keysSorted,function(key,i){
                    categories[key] = tempCategories[key];
                });

            }

            App.filtredObj = categories;

            _.each(categories,function(tabOfItem,categorieName){
                if(App.selectedParams.acteurs.length>0){
                    var acteurUK = _.find(App.utils.acteurs,function(a){return a.acteur_nom == categorieName}).id;
                    var acteurURL = "acteurs.php#"+App.selectedLang+"/[]/[]/[]/nb_objets/desc/"+acteurUK;
                    var html = '<div class="selectionBox"><div class="selectionColor"></div><span class="selectionItemName">'+categorieName+'&nbsp'+'<span class="selectionItemNumber">('+tabOfItem.length+')</span></span><a href="'+acteurURL+'" target="_blank"><div class="selectionItemExtLink"></div></a></div>';
                } else{
                    var html = '<div class="selectionBox"><div class="selectionColor"></div><span class="selectionItemName">'+categorieName+'&nbsp'+'<span class="selectionItemNumber">('+tabOfItem.length+')</span></span></div>';    
                }
                $("#selectedTransactionsContainer").append(html);
            });
        }
    },

    selectOneFiltre:function($checkbox){
        var self = this

        var dataOptionValue = $checkbox.attr("data-option");
        var dataType = $checkbox.parents(".filtreOptionsContainer").attr("data-options");
        myNewParams = App.selectedParams[dataType];

        if(App.selectedParams[dataType].includes(dataOptionValue)){
            myNewParams = _.without(App.selectedParams[dataType], dataOptionValue);
        }else{
            myNewParams.push(dataOptionValue);
        }   

        _.each(App.selectedParams,function(tabSelectedOption,filtre){
            if(dataType != "vasetypes"){
                App.selectedParams[filtre] = [];
            }
        });

        App.selectedParams[dataType] = myNewParams;
        $(".cancelFilterBtn").addClass("reveal");
        App.update();
    },

    closeTransactionModale:function(){
        var self = this;
        $("#graphTransactionModale").removeClass("show");
        $(".highcharts-series .highcharts-graph").attr("data-stroke", "white");
        _.each(App.myHighchart.series,function(s,i){
            _.each(s.points,function(p,j){
                p.setState("");
            });
        });
        App.selectedParams.idobjet = [];
        App.selectedParams.idvente = [];
        this.router.updateRoute();
    },

    bindEvents:function(){
    	var self = this;

        $(window).on("resize",function(){
            self.renderChart();
        });

        $(".filtreBox").on("click",function(){
            var filtre = $(this).attr("data-filtre");
            if($(".filtreOptionsContainer[data-options='"+filtre+"']").hasClass("display")){
                $(".filtreOptionsContainer").removeClass("display");    
            }else{
                $(".filtreOptionsContainer").removeClass("display");
                $(".filtreOptionsContainer[data-options='"+filtre+"']").addClass("display");    
            }
        });

        $(".optionLine").on("click",function(){
            self.selectOneFiltre($(this).find(".optionCheckbox"));
        });

        $(".filtreBoxCancelBtn").on("click",function(){
            if($(this).parents(".filtreBoxContainer").find(".filtreBox").attr("data-filtre")=="vasetypes"){
                App.selectedParams.vasetypes = [];
            }else{
                _.each(App.selectedParams,function(tabSelectedOption,filtre){
                    App.selectedParams[filtre] = [];
                });
            }
            $(".filtreOptionsContainer").removeClass("display");
            App.update();
        });

        $("#rightPanelFooter .cancelFilterBtn").on("click",function(){
            _.each(App.selectedParams,function(tabSelectedOption,filtre){
                    App.selectedParams[filtre] = [];
            });
            App.update();
        });

        $("#rightPanel").on("click",function(e){
            if(e.target !== e.currentTarget) return;
            $(".filtreOptionsContainer").removeClass("display");
            self.closeTransactionModale();
        });

        $("#chartMethodoBtn").on("click",function(){
            $("#GraphMethodoModale").addClass("show");
            $(".filtreOptionsContainer").removeClass("display");
            self.closeTransactionModale();
            return false;
        });

        $(".highcharts-background").on("click",function(){
            self.closeTransactionModale();
        });

        $("#modaleValeurLink").on("click",function(){
            var obj = $(this).attr("data-obj");
            location.href = "story.php#"+App.selectedLang+"/"+obj;
        });

        $("#zoomResetBtn").on("click",function(){
            App.myHighchart.xAxis[0].setExtremes(null, null);
            App.xAxisYears = [];
            App.myHighchart.yAxis[0].setExtremes(null, null);
            $(this).removeClass("show");
        });

        $("#transactionsModaleCloseBtn").on("click",function(){
            self.closeTransactionModale();
        });

        

    }
};

module.exports = App;
window.App = App;