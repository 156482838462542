var FooterView = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.$CreditsModal = $("#CreditsModale");
        this.$DownloadModal = $("#DownloadModale");
        this.$creditsPhotoContainer = $(".credits_content_photo");
    },

    render:function(){
        this.bindEvents();
        this.fillCreditsPhoto();
    },

    fillCreditsPhoto:function(){
        var self = this;
        _.each(App.utils.creditsphoto, function(cp){
            var $cpLine = $("<p><span class='cp_name'>"+cp.location+", "+cp.cotenum+"</span><span class='cp_location'>"+cp.titulaire+"</span></p>");
            self.$creditsPhotoContainer.append($cpLine);
        });
    },

    bindEvents:function(){
        var self = this;

        $(".footer_bt[data-bt='credits']").on("click", function(){
            self.displayCreditsPopin(); 
        });

        $("#CreditsModale .modal_closebt").on("click", function(){
            self.closeCreditsPopin();
        });

        $(".footer_bt[data-bt='telecharger']").on("click", function(){
            self.displayDownloadPopin(); 
        });

        $("#DownloadModale .modal_closebt").on("click", function(){
            self.closeDownloadPopin();
        });

        $("#SaveModal .modal_closebt, #TimelineModal .modal_closebt, #MethodoModal, #SummaryModal .modal_closebt").on("click", function(){
            $("#ModalCache").removeClass("displayed");
            $(".modalINHA").removeClass("show");
        });

    },

    displayCreditsPopin:function(){
        $(".modalINHA").removeClass("show");
        this.$CreditsModal.addClass("show");
    },

    closeCreditsPopin:function(){
        this.$CreditsModal.removeClass("show");
    },

    displayDownloadPopin:function(){
        $(".modalINHA").removeClass("show");
        this.$DownloadModal.addClass("show");
    },

    closeDownloadPopin:function(){
        this.$DownloadModal.removeClass("show");
    }
});

module.exports = FooterView;