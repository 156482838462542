var StorySidebarView = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.$Sidebar = $("#sideBar");
        this.$creation = $(".infoContainer[data-step='creation']");
        this.$decouverte = $(".infoContainer[data-step='decouverte']");
        this.$ventes = $(".infoContainer[data-step='ventes']");
        this.$conservation = $(".infoContainer[data-step='conservation']");

        
        if(1281<window.innerWidth){
            this.scrollLenght = 350;
        }else{
            this.scrollLenght = 260;
        }
    },

    render:function(){
        var self = this;
        setTimeout(function(){
            self.$Sidebar.addClass("displayed");
        }, App.introTimeout);
        this.bindEvents();
        this.buildVenteContainer();
        this.informationManquanteLabel = App.utils.translations["information-manquante"][App.selectedLang];
        this.informationIncertaineLabel = App.utils.translations["information-incertaine"][App.selectedLang];
        this.informationCertaineLabel = App.utils.translations["information-certaine"][App.selectedLang];

        console.log(this.informationManquanteLabel)
    },

    bindEvents:function(){
        var self = this
        $(".tooltipBtn").on("mouseenter",function(){
            self.displayTooltip($(this));
        });

        $(".tooltipBtn").on("mouseleave",function(){
           $(".tooltipBtn").removeClass("hovered");
           $("#popInContainer").removeClass("reveal");
        });

    },

    buildVenteContainer:function(){
        var self = this
        $(".modaleRotateBtn").unbind("click");
        var ventesIds = App.ventesIDS;
        $("#objName").html(App.utils.objets[App.objID].nom_objet);
        var nbDataContainer = (4+ventesIds.length)*100;
        var conservationLeft = (3*this.scrollLenght)+(this.scrollLenght*ventesIds.length)+"px";

        $(".infoContainer[data-step='conservation']").css("left",conservationLeft);
        $("#infoWrapper").css("width",nbDataContainer+"%");
        $("#infoWrapper").css("width",nbDataContainer+"%");

        for(var i = 0;i<ventesIds.length;i++){
            var data = self.gatherOneVenteData(ventesIds[i]);

            var objAcheteur = App.utils.acteurs[data.uk_acheteur];
            //var urlAcheteur = objAcheteur.acteur_url;
            var objVendeur = App.utils.acteurs[data.uk_vendeur];
            //var urlVendeur = objVendeur.acteur_url;
            // acteurs.php#fr/[]/[]/[]/nb_objets/desc/data.uk_acheteur
            var left = (2*this.scrollLenght)+(this.scrollLenght*i)+"px";
            var curentVente = i+1;
            if(typeof objAcheteur=="undefined"&&typeof objVendeur == "undefined"){
                var container = '<div class="infoContainer" data-step="ventes" style="left:'+left+'" data-venteinc="'+i+'"><div class="venteHeader"><p class="venteCounter">'+App.utils.translations["vente-n"][App.selectedLang]+' '+curentVente+'</span>/'+ventesIds.length+'</p><p class="venteName">'+data.nom+'</p></div><p class="labeldata" data-label="vente-lieu">'+App.utils.translations["lieu"][App.selectedLang]+'</p><p class="infodata" data-info="vente-lieu">'+data.ville+'</p><p class="labeldata" data-label="date-vente">'+App.utils.translations["date"][App.selectedLang]+'</p><p class="infodata" data-info="date-vente">'+data.date+'</p><p class="labeldata" data-label="vendeur-vente">'+App.utils.translations["vendeur"][App.selectedLang]+'</p><p class="infodata" data-info="vendeur-vente">'+data.vendeur+'</p><p class="labeldata" data-label="acheteur-vente">'+App.utils.translations["acheteur"][App.selectedLang]+'</p><p class="infodata" data-info="acheteur-vente">'+data.acheteur+'</p><p class="labeldata" data-label="prix-vente">'+App.utils.translations["prix"][App.selectedLang]+'</p><p class="infodata" data-info="prix-vente">'+data.prix+'</p><a class="venteExtLinkRef" target="_blank" href="'+data.url+'"><div class="venteLinkExtContainer"><div class="venteLinkExtBar"><span class="venteLinkExtBarLabel">Plus d\'infos</span></div><div class="venteLinkArrowContainer"></div></div></a></div>';
            } else if(typeof objAcheteur!="undefined"&&typeof objVendeur == "undefined"){
                var container = '<div class="infoContainer" data-step="ventes" style="left:'+left+'" data-venteinc="'+i+'"><div class="venteHeader"><p class="venteCounter">'+App.utils.translations["vente-n"][App.selectedLang]+' '+curentVente+'</span>/'+ventesIds.length+'</p><p class="venteName">'+data.nom+'</p></div><p class="labeldata" data-label="vente-lieu">'+App.utils.translations["lieu"][App.selectedLang]+'</p><p class="infodata" data-info="vente-lieu">'+data.ville+'</p><p class="labeldata" data-label="date-vente">'+App.utils.translations["date"][App.selectedLang]+'</p><p class="infodata" data-info="date-vente">'+data.date+'</p><p class="labeldata" data-label="vendeur-vente">'+App.utils.translations["vendeur"][App.selectedLang]+'</p><p class="infodata" data-info="vendeur-vente">'+data.vendeur+'</p><p class="labeldata" data-label="acheteur-vente">'+App.utils.translations["acheteur"][App.selectedLang]+'</p><p class="infodata" data-info="acheteur-vente">'+data.acheteur+'</p><div class="linkToActeur" data-acteur='+data.uk_acheteur+'><div class="linkActeurBox">'+App.utils.translations["sa-fiche"][App.selectedLang]+'<div class="acteurLinkArrowContainer"></div></div></div><p class="labeldata" data-label="prix-vente">'+App.utils.translations["prix"][App.selectedLang]+'</p><p class="infodata" data-info="prix-vente">'+data.prix+'</p><a class="venteExtLinkRef" target="_blank" href="'+data.url+'"><div class="venteLinkExtContainer"><div class="venteLinkExtBar"><span class="venteLinkExtBarLabel">'+App.utils.translations["plus-infos"][App.selectedLang]+'</span></div><div class="venteLinkArrowContainer"></div></div></a></div>';
            }else if(typeof objAcheteur=="undefined"&&typeof objVendeur != "undefined"){
                var container = '<div class="infoContainer" data-step="ventes" style="left:'+left+'" data-venteinc="'+i+'"><div class="venteHeader"><p class="venteCounter">'+App.utils.translations["vente-n"][App.selectedLang]+' '+curentVente+'</span>/'+ventesIds.length+'</p><p class="venteName">'+data.nom+'</p></div><p class="labeldata" data-label="vente-lieu">'+App.utils.translations["lieu"][App.selectedLang]+'</p><p class="infodata" data-info="vente-lieu">'+data.ville+'</p><p class="labeldata" data-label="date-vente">'+App.utils.translations["date"][App.selectedLang]+'</p><p class="infodata" data-info="date-vente">'+data.date+'</p><p class="labeldata" data-label="vendeur-vente">'+App.utils.translations["vendeur"][App.selectedLang]+'</p><p class="infodata" data-info="vendeur-vente">'+data.vendeur+'</p><div class="linkToActeur" data-acteur='+data.uk_vendeur+'><div class="linkActeurBox">'+App.utils.translations["sa-fiche"][App.selectedLang]+'<div class="acteurLinkArrowContainer"></div></div></div><p class="labeldata" data-label="acheteur-vente">'+App.utils.translations["acheteur"][App.selectedLang]+'</p><p class="infodata" data-info="acheteur-vente">'+data.acheteur+'</p><p class="labeldata" data-label="prix-vente">Prix</p><p class="infodata" data-info="prix-vente">'+data.prix+'</p><a class="venteExtLinkRef" target="_blank" href="'+data.url+'"><div class="venteLinkExtContainer"><div class="venteLinkExtBar"><span class="venteLinkExtBarLabel">'+App.utils.translations["plus-infos"][App.selectedLang]+'</span></div><div class="venteLinkArrowContainer"></div></div></a></div>';
            }else{
                var container = '<div class="infoContainer" data-step="ventes" style="left:'+left+'" data-venteinc="'+i+'"><div class="venteHeader"><p class="venteCounter">'+App.utils.translations["vente-n"][App.selectedLang]+' '+curentVente+'</span>/'+ventesIds.length+'</p><p class="venteName">'+data.nom+'</p></div><p class="labeldata" data-label="vente-lieu">'+App.utils.translations["lieu"][App.selectedLang]+'</p><p class="infodata" data-info="vente-lieu">'+data.ville+'</p><p class="labeldata" data-label="date-vente">'+App.utils.translations["date"][App.selectedLang]+'</p><p class="infodata" data-info="date-vente">'+data.date+'</p><p class="labeldata" data-label="vendeur-vente">'+App.utils.translations["vendeur"][App.selectedLang]+'</p><p class="infodata" data-info="vendeur-vente">'+data.vendeur+'</p><div class="linkToActeur" data-acteur='+data.uk_vendeur+'><div class="linkActeurBox">'+App.utils.translations["sa-fiche"][App.selectedLang]+'<div class="acteurLinkArrowContainer"></div></div></div><p class="labeldata" data-label="acheteur-vente">'+App.utils.translations["acheteur"][App.selectedLang]+'</p><p class="infodata" data-info="acheteur-vente">'+data.acheteur+'</p><div class="linkToActeur" data-acteur='+data.uk_acheteur+'><div class="linkActeurBox">'+App.utils.translations["sa-fiche"][App.selectedLang]+'<div class="acteurLinkArrowContainer"></div></div></div><p class="labeldata" data-label="prix-vente">'+App.utils.translations["prix"][App.selectedLang]+'</p><p class="infodata" data-info="prix-vente">'+data.prix+'</p><a class="venteExtLinkRef" target="_blank" href="'+data.url+'"><div class="venteLinkExtContainer"><div class="venteLinkExtBar"><span class="venteLinkExtBarLabel">'+App.utils.translations["plus-infos"][App.selectedLang]+'</span></div><div class="venteLinkArrowContainer"></div></div></a></div>';
            }
            $(".infoContainer[data-step='conservation']").before(container);
        }
        $("#venteTotal").html(ventesIds.length);

        $(".linkToActeur").on("click",function(){
            location.href = "acteurs.php#"+App.selectedLang+"/[]/[]/[]/nb_objets/desc/"+$(this).attr("data-acteur");
        });
        
    },

    gatherCreation:function(){
        var self = this;
        var obj = App.utils.objets[App.objID];
        $("#objName").html(obj.nom_objet);
        this.$creation.find(".infodata[data-info='naissance-lieu']").html(obj.lieu_creation_nom);
        this.$creation.find(".infodata[data-info='naissance-date']").html(App.utils.rewriteDateString(obj.date_creation_string));
        this.$creation.find(".infodata[data-info='attribution']").html(obj.auteur_objet);
        
        if(obj.matiere_filtre == null){
            this.$creation.find(".infodata[data-info='matiere']").html(this.informationManquanteLabel);
        }else{
            this.$creation.find(".infodata[data-info='matiere']").html(obj.matiere_filtre);
        }
        
        if(obj.dim_cm == "Information manquante"){
            this.$creation.find(".infodata[data-info='dimensions']").html(this.informationManquanteLabel);
        }else{
            this.$creation.find(".infodata[data-info='dimensions']").html(obj.dim_cm);
        }

        var url = obj.URL_objet_AGORHA;
        $("#extLinkRef").attr("href",url);

        $("#infoHeader").attr("class","creation");

        self.scaleComparaison(obj);
        
        this.translateInfocontainer("0px");
    },

    gatherDecouverte:function(){
        var self = this;
        var obj = App.utils.objets[App.objID];
        $("#objName").html(obj.nom_objet);

        if(obj.lieu_decouverte_nom == "Information manquante" && obj.lieu_decouverte_pays == "Information manquante"){
            this.$decouverte.find(".infodata[data-info='decouverte-lieu']").html(this.informationManquanteLabel);
        } else{
            this.$decouverte.find(".infodata[data-info='decouverte-lieu']").html(obj.lieu_decouverte_nom+" ("+obj.lieu_decouverte_pays+")");    
        }

        if(obj.lieu_decouverte_certitude == "certain"){
            this.$decouverte.find(".infoprecise[data-precise='decouverte-lieu']").html(this.informationCertaineLabel).attr("data-certitude","certain");
        }else{
            this.$decouverte.find(".infoprecise[data-precise='decouverte-lieu']").html(this.informationIncertaineLabel).attr("data-certitude","incertain");
        }
        if(typeof obj.date_decouverte_string == "undefined" || obj.date_decouverte_string == "Information manquante"){
            this.$decouverte.find(".infodata[data-info='decouverte-date']").html(this.informationManquanteLabel);
        }else{
            console.log(obj.date_decouverte_string)
            this.$decouverte.find(".infodata[data-info='decouverte-date']").html(obj.date_decouverte_string);
        }
        

        $("#infoHeader").attr("class","creation");
        this.translateInfocontainer("-"+this.scrollLenght+"px");
    },

    gatherOneVenteData:function(venteID){

        var self = this;
        var vente = App.utils.ventes[venteID];
        var nom = vente.vente_nom;
        var ville = vente.vente_ville;
        var adresse = vente.vente_adresse;
        var vendeur = vente.vente_vendeur;
        var acheteur = vente.vente_acheteur;
        var prix = vente.ventes_prix_francs;
        var date = vente.vente_date;
        var url = vente.url_vente;
        var uk_acheteur = vente.uk_acteur_vente_acheteur
        var uk_vendeur = vente.uk_acteur_vente_vendeur

        var venteData = {
            nom:nom,
            ville:ville,
            adresse:adresse,
            vendeur:vendeur,
            acheteur:acheteur,
            prix:prix,
            date:date,
            url:url,
            uk_acheteur:uk_acheteur,
            uk_vendeur:uk_vendeur
        }
        
        _.each(venteData,function(value,key){
            if(value == ""){
                venteData[key] = this.informationManquanteLabel;
            } else if(typeof value == "undefined"){
                venteData[key] = this.informationManquanteLabel;
            }
        });
        
        return(venteData);

    },

    gatherVentes:function(){
        var self = this;
        var left = -(2*this.scrollLenght)-(App.stepVenteInc*this.scrollLenght)+"px";

        var vente = App.utils.ventes[App.ventesIDS[App.stepVenteInc]];

        $("#venteEnCours").html(App.stepVenteInc+1);
        $("#venteName").html(vente.vente_nom);

        $("#infoHeader").attr("class","vente");
        this.translateInfocontainer(left);
    },

    gatherConservation:function(){
        var conservationLeft = -(3*this.scrollLenght)-(this.scrollLenght*App.ventesIDS.length)+"px";

        var obj = App.utils.objets[App.objID];

        $("#conservationName").html(obj.conservation_nom);
        $("#objName").html(obj.nom_objet);
        this.$conservation.find(".infodata[data-info='lieu-conservation']").html(obj.conservation_ville+" / "+obj.conservation_pays);
        if(obj.conservation_ville == "Information manquante" && obj.conservation_pays=="Information manquante"){
            this.$conservation.find(".infodata[data-info='lieu-conservation']").html(this.informationManquanteLabel);
        }else{
            this.$conservation.find(".infodata[data-info='lieu-conservation']").html(obj.conservation_ville+" / "+obj.conservation_pays);
        }
        this.$conservation.find(".infodata[data-info='date-conservation']").html(obj.conservation_date_entree);
        $("#infoHeader").attr("class","conservation");
        this.translateInfocontainer(conservationLeft);
    },

    scaleComparaison:function(obj){
        var self = this;
        var scale = parseInt(obj.echelle_cm)

        if(obj.categorie_picto == "mobilier / instrument"){
            $("#comparaisonPictoContainer").css("background-image","url('./img/collection/default/objet-mobilier-defaut.svg')")
        }else{
            $("#comparaisonPictoContainer").css("background-image","url('./img/collection/default/"+obj.categorie_picto+"-defaut.svg')")
        }
        if(isNaN(scale)){
        }else{
            if(scale>40){
                $("#comparaisonScaleContainer").addClass("arm");
                var cmInPx = 60/scale;
                var heightPxHand = 40*cmInPx;
                var widthPxHand = 16/65*heightPxHand;
                $("#comparaisonScaleContainer").css("height",heightPxHand+"px").css("width",widthPxHand+"px");
            }else{
                $("#comparaisonScaleContainer").addClass("hand");
                if(scale>20){
                    var cmInPx = 60/scale;
                    var heightPxHand = 20*cmInPx;
                    var widthPxHand = 16/25*heightPxHand;
                    $("#comparaisonScaleContainer").css("height",heightPxHand+"px").css("width",widthPxHand+"px");
                }else{
                    var cmInPx = 50/20;
                    var heightPxObj = scale*cmInPx;
                    var widthPxObj = 40/60*heightPxObj;
                    $("#comparaisonPictoContainer").css("height",heightPxObj+"px").css("width",widthPxObj+"px");
                }
            }
        }   
    },

    translateInfocontainer:function(left){
        var self = this;
        $("#infoWrapper").css("left",left)
    },

    displayTooltip:function($el){
        var self = this;
        $(".popInTxt").removeClass("selected");
        if($el.attr("data-tooltip")=="attribution"){
            $("#popInAttributionTxt").addClass("selected");
        }else{
            $("#popInPrecisionTxt").addClass("selected");
        }

        var t = $el.offset().top;
        var h = $("#popInContainer").outerHeight();
        var p = t - (h/2) + 14;
        $("#popInContainer").css("top",p+"px");

        $("#popInContainer").addClass("reveal");
        $el.addClass("hovered");

    },

});

module.exports = StorySidebarView;