var App = {
    $body:$("body"),

    init: function init() {
        this.isLoaded = false;
        this.$App = $("#App");
        this.$Modal = $("#ActeurModale");
        this.$itemsWrapper = $(".collection_section[data-section='collection']");
        this.$collectionItems = $(".collection_items");
        this.selectedLang = "fr";
        this.nbCheckedImages = 0;
        this.selectedActeur = "n";

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("routerActeurs");
        this.router = new Router();

        this.filteredActeurs = [];

        this.selectedVentes = [];
        this.selectedTypes = [];
        this.selectedNatures = [];

        this.selectedSortingBlock = "0";
        this.selectedSorting = "asc";

        this.ventesData = {};
        this.typesData = {};

        this.autocompleteSource = [];

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        this.isLoaded = true;
        this.utils.gatherData(function(){
            self.render();
        });
    },

    render:function(){
        var self = this;
    	this.utils.fillTranslations();
    	this.headerView.render();
        this.footerView.render();
        $("#collectionSearchInput").attr("placeholder",App.utils.translations["rechercher-une-oeuvre"][App.selectedLang]);
        this.computeCollectionData(function(){
            self.initializeFilters();
            self.displayAllObjects();
            self.updateCollection(true);
            self.bindEvents();
        });
        
    },

    computeCollectionData:function(callback){
        var self = this;
        _.each(App.utils.acteurs, function(a){
            if(a.type !== undefined){
                self.autocompleteSource.push({
                    value:a.id,
                    label:a.acteur_nom
                });
            }

            var objImg = "img/acteurs/thumbs/"+a.id+".jpg";
            App.utils.imageExists(objImg, function(imgExists){
                a.img = objImg;
                a.imgExists = imgExists;
                self.nbCheckedImages += 1;
                if(self.nbCheckedImages == _.size(App.utils.acteurs)){
                    return callback();
                }
            });
        });
    },

    initializeFilters:function(){
        var self = this;

        _.each(App.utils.acteurs, function(a){
            if(a.type !== undefined){
                var slugType = _.str.slugify(a.type);

                if(self.typesData[slugType] === undefined){
                    self.typesData[slugType] = {
                        slug:slugType,
                        nom:a.type
                    };
                    $(".menu_selectbox[data-selectbox='types'] .selectbox_options").append("<div class='selectbox_option' data-value='"+slugType+"'><div class='option_checkbox'></div><div class='option_label'>"+App.utils.translations[a.type][App.selectedLang]+"</div></div>");
                }
            }
        });

        $(".menu_selectbox[data-selectbox='types'] .selectbox_options").append("<div class='selectbox_options_nature'><div class='selectbox_option' data-value='personne'><div class='option_checkbox'></div><div class='option_label'>Personne physique</div></div><div class='selectbox_option' data-value='institution'><div class='option_checkbox'></div><div class='option_label'>Institution</div></div></div>");

        this.uniqVentes = {};
        _.each(App.utils.ventes, function(v){
            var slugNomVente = _.str.slugify(v.vente_nom);
            if(self.ventesData[slugNomVente] === undefined){
                self.ventesData[slugNomVente] = {
                    slug:slugNomVente,
                    vente_nom:v.vente_nom,
                    objets:[v.id_objet],
                };
                self.uniqVentes[slugNomVente] = {
                    slug:slugNomVente,
                    vente_nom:v.vente_nom,
                    timestamp:v.vente_date_ts
                };
            }else{
                if(_.indexOf(self.ventesData[slugNomVente].objets, v.id_objet) == -1){
                    self.ventesData[slugNomVente].objets.push(v.id_objet)
                }
            }
        });


        var sortedUniqVentes = _.sortBy(this.uniqVentes, function(v){ return v.timestamp; });
        _.each(sortedUniqVentes, function(sv){
            $(".menu_selectbox[data-selectbox='ventes'] .selectbox_options").append("<div class='selectbox_option' data-value='"+sv.slug+"'><div class='option_checkbox'></div><div class='option_label'>"+sv.vente_nom.split("Vente ")[1]+"</div></div>");
        });
    },

    updateCollection:function(sorting){
        var self = this;
        this.isCancelable = false;

        if(this.selectedActeur !== "" && this.selectedActeur !== "n"){
            this.displayActeurModal(this.selectedActeur);
        }

        this.filterCollection();
        this.updateMenu();

        if(this.selectedSortingBlock !== "0"){
            self.isCancelable = true;
            if(this.selectedSortingBlock == "nb_objets"){
                if(this.selectedSorting == "asc"){
                    App.filteredActeurs = _.sortBy(App.filteredActeurs, function(o){
                        return o.objets.length;
                    });
                }else{
                    App.filteredActeurs = _.sortBy(App.filteredActeurs, function(o){
                        return - o.objets.length;
                    });
                }
            }else if(this.selectedSortingBlock == "nb_ventes"){
                if(this.selectedSorting == "asc"){
                    App.filteredActeurs = _.sortBy(App.filteredActeurs, function(o){
                        return o.uniqVentes;
                    });
                }else{
                    App.filteredActeurs = _.sortBy(App.filteredActeurs, function(o){
                        return - o.uniqVentes;
                    });
                }
            }else if(this.selectedSortingBlock == "valeur_vente"){
                App.filteredActeurs = _.filter(App.filteredActeurs, function(a){
                    return a.totalVentes > 0;
                });
                if(this.selectedSorting == "asc"){
                    App.filteredActeurs = _.sortBy(App.filteredActeurs, function(o){
                        return o.totalVentes;
                    });
                }else{
                    App.filteredActeurs = _.sortBy(App.filteredActeurs, function(o){
                        return - o.totalVentes;
                    });
                }
            }else if(this.selectedSortingBlock == "valeur_achat"){
                App.filteredActeurs = _.filter(App.filteredActeurs, function(a){
                    return a.totalAchats > 0;
                });
                if(this.selectedSorting == "asc"){
                    App.filteredActeurs = _.sortBy(App.filteredActeurs, function(o){
                        return o.totalAchats;
                    });
                }else{
                    App.filteredActeurs = _.sortBy(App.filteredActeurs, function(o){
                        return - o.totalAchats;
                    });
                }
            }
        }
        $(".collection_section[data-section='menu']").attr("data-cancelable", this.isCancelable);
        this.displayAllObjects();
        this.router.updateRoute();
    },

    filterCollection:function(){
        var self = this;

        this.filteredActeurs = [];
        var neededRequired = 3;

        _.each(App.utils.acteurs, function(a){
            var required = 0;
            if(a.type !== undefined){
                if(self.selectedVentes.length > 0){
                    self.isCancelable = true;
                    var objetVentesNoms = _.map(a.transactions, function(transac){ return _.str.slugify(transac.vente_nom); });
                    if(_.intersection(self.selectedVentes, objetVentesNoms).length > 0){
                        required += 1;
                    }else{

                    }
                }else{
                    required += 1;
                }

                if(self.selectedTypes.length > 0){
                    self.isCancelable = true;
                    var slugType = _.str.slugify(a.type);
                    var tmpSelectedTypes = JSON.parse(JSON.stringify(self.selectedTypes));
                    if(_.indexOf(self.selectedTypes, "acheteur") > -1 || _.indexOf(self.selectedTypes, "vendeur") > -1){
                        tmpSelectedTypes.push("acheteur-vendeur");
                    }
                    if(_.indexOf(tmpSelectedTypes, slugType) > -1){
                        required += 1;
                    }else{

                    }
                }else{
                    required += 1;
                }

                if(self.selectedNatures.length > 0){
                    self.isCancelable = true;
                    var slugNature = _.str.slugify(a.acteur_nature);
                    if(_.indexOf(self.selectedNatures, slugNature) > -1){
                        required += 1;
                    }else{

                    }
                }else{
                    required += 1;
                }

                if(required == neededRequired){
                    self.filteredActeurs.push(a);
                }
            }
        });
    },

    displayAllObjects:function(sorting){
        var self = this;
        this.$collectionItems.empty();

        displayedObjects = App.filteredActeurs;
        console.log(displayedObjects);

        var sizeDisplayed = _.size(displayedObjects)
        $(".collection_title_nb").html(sizeDisplayed);
        if(sizeDisplayed <= 1){
            $(".collection_title_label").html(App.utils.translations["acteurs"][App.selectedLang]);
        }else{
            $(".collection_title_label").html(App.utils.translations["acteurs"][App.selectedLang]);
        }

        var itemsToAppend = [];

        _.each(displayedObjects, function(o){
            var $item = $("<div class='collection_item' data-objet-id='"+o.uk_acteur+"'></div>");

            var $itemImage = "";
            var slugType = _.str.slugify(o.type);
            if(o.imgExists){
                $itemImage = $("<div class='item_image_container'><img class='item_image' src='"+o.img+"' /></div>");
            }else if(o.acteur_nature=="institution"){
                $itemImage = $("<div class='item_image_container'><img class='item_image' src='img/acteurs/acteurs-musee.svg' /></div>");
            }else{
                $itemImage = $("<div class='item_image_container'><img class='item_image' src='img/acteurs/acteurs-"+slugType+".svg' /></div>");
            }

            var $itemIdentity = $("<div class='item_identity'><div class='identity_name'>"+_.str.prune(o.acteur_nom, 50)+"</div></div>");

            var $itemType = $("<div class='item_types'></div>");

            if(o.type == "acheteur-vendeur") $itemType.html("<div class='type_block' data-type='acheteur'>"+App.utils.translations["acheteur"][App.selectedLang]+"</div><div class='type_block' data-type='end'>&</div><div class='type_block' data-type='vendeur'>"+App.utils.translations["vendeur"][App.selectedLang]+"</div>");
            else $itemType.html("<div class='type_block' data-type='"+o.type+"'>"+App.utils.translations[o.type][App.selectedLang]+"</div>");

            var strObjets = App.utils.translations["objets"][App.selectedLang];
            var uniqLength = _.uniq(o.objets).length
            if(uniqLength <= 1) strObjets = App.utils.translations["objet"][App.selectedLang];
            var $itemObjets = $("<div class='item_objets displayed'>"+uniqLength+" "+strObjets+"</div>");

            var strVentes =App.utils.translations["ventes"][App.selectedLang];;
            if(o.uniqVentes <= 1) strVentes =App.utils.translations["vente"][App.selectedLang];;
            var $itemNbVentes = $("<div class='item_nbventes displayed'>"+o.uniqVentes+" "+strVentes+"</div>");

            var totalAchats = o.totalAchats;
            var totalVentes = o.totalVentes;
            if(App.selectedVentes.length > 0){
                totalAchats = 0;
                totalVentes = 0;
                var filteredTransactions = _.filter(o.transactions, function(t){
                    return (_.indexOf(App.selectedVentes, _.str.slugify(t.vente_nom)) > -1);
                });
                _.each(filteredTransactions, function(ft){
                    if(o.nom == ft.vente_vendeur) totalVentes += ft.ventes_prix_value;
                    else if(o.nom = ft.vente_acheteur) totalAchats += ft.ventes_prix_value;
                });
            }

            var strFrancs = "francs";
            var $itemAchats = $("<div class='item_achats'></div>");
            if(o.totalAchats <= 1) strFrancs = "franc";
            if(o.totalAchats > 0) $itemAchats.addClass("displayed")
                                            .html(_.str.numberFormat(totalAchats, 0, ".", " ")+" "+strFrancs);

            strFrancs = "francs";
            var $itemVentes = $("<div class='item_ventes'></div>");
            if(o.totalVentes <= 1) strFrancs = "franc";
            if(o.totalVentes > 0) $itemVentes.addClass("displayed")
                                            .html(_.str.numberFormat(totalVentes, 0, ".", " ")+" "+strFrancs+"</div>");

            var $itemExtbt = $("<a class='item_extbt' data-acteur='"+o.uk_acteur+"'><span class='item_extbt_label'>"+App.utils.translations["voir-sa-fiche"][App.selectedLang]+"</span></a>");

            $item.append($itemImage)
                .append($itemIdentity)
                .append($itemType)
                .append($itemObjets)
                .append($itemNbVentes)
                .append($itemVentes)
                .append($itemAchats)
                .append($itemExtbt);
            self.$collectionItems.append($item);
        });
    },

    updateMenu:function(){
        var self = this;

        this.availableVentes = [];
        this.availableTypes = [];
        this.availableNatures = [];

        $(".selectbox_option").removeClass("available");
        $(".block_sortbt").removeClass("selected");
        $(".sorting_block[data-block='"+App.selectedSortingBlock+"'] .block_sortbt[data-sorting='"+App.selectedSorting+"']").addClass("selected");

        _.each(App.filteredActeurs, function(o){
            var objetVentesNoms = _.map(o.transactions, function(transac){ return _.str.slugify(transac.vente_nom); });
            _.each(objetVentesNoms, function(slugNomVente){
                if(_.indexOf(self.availableVentes, slugNomVente) == -1){
                    self.availableVentes.push(slugNomVente);
                    $(".menu_selectbox[data-selectbox='ventes'] .selectbox_option[data-value='"+slugNomVente+"']").addClass("available");
                }
            });

            var slugType = _.str.slugify(o.type);
            if(_.indexOf(self.availableTypes, slugType) == -1){
                self.availableTypes.push(slugType);
                $(".menu_selectbox[data-selectbox='types'] .selectbox_option[data-value='"+slugType+"']").addClass("available");
            }
            if(_.indexOf(self.availableNatures, o.acteur_nature) == -1){
                self.availableNatures.push(o.acteur_nature);
                $(".menu_selectbox[data-selectbox='types'] .selectbox_options_nature .selectbox_option[data-value='"+o.acteur_nature+"']").addClass("available");
            }
        });

        $(".menu_selectbox[data-selectbox='ventes'] .selectbox_option").removeClass("selected");
        _.each(App.selectedVentes, function(v){
            $(".menu_selectbox[data-selectbox='ventes'] .selectbox_option[data-value='"+v+"']").addClass("selected");
        });

        $(".menu_selectbox[data-selectbox='types'] .selectbox_option").removeClass("selected");
        _.each(App.selectedTypes, function(v){
            $(".menu_selectbox[data-selectbox='types'] .selectbox_option[data-value='"+v+"']").addClass("selected");
        });

        $(".menu_selectbox[data-selectbox='types'] .selectbox_options_nature .selectbox_option").removeClass("selected");
        _.each(App.selectedNatures, function(v){
            $(".menu_selectbox[data-selectbox='types'] .selectbox_options_nature .selectbox_option[data-value='"+v+"']").addClass("selected");
        });
    },

    bindEvents:function(){
    	var self = this;

        $(".menu_cancelbt").on("click", function(){
            self.cancelFilters();
        });

        $(".collection_menu .menu_selectbox").on("click", function(){
            var toExpand = false;
            if(!$(this).hasClass("expanded")) toExpand = true;
            $(".menu_selectbox.expanded").removeClass("expanded");
            if(toExpand) $(this).addClass("expanded");
            return false;
        });

        $(".collection_menu .menu_selectbox .selectbox_option").on("click", function(){
            if($(this).parents(".selectbox_options_nature").length >= 1){
                self.selectOption($(this), true);
            }else{
                self.selectOption($(this), false);
            }
            /*if($(this).hasClass("available")){
                self.selectOption($(this));
            }else{
                self.selectNewOption($(this));
            }*/
            
            return false;
        });

        $(".collection_menu_sorting .block_sortbt").on("click", function(){
            if($(this).hasClass("selected")){
                self.clearSorting($(this));
            }else{
                self.updateSorting($(this));
            }
        });

        this.$App.on("click", function(){
            $(".menu_selectbox.expanded").removeClass("expanded");
            $(".collection_section").removeClass("fade");
        });

        $( "#collectionSearchInput" ).autocomplete({
          minLength: 0,
          source: self.autocompleteSource,
          response:function(event, ui){
            self.searchArrayAutocomplete(ui.content);
          },
          select: function( event, ui ) {
            $( "#collectionSearchInput" ).val(ui.item.label);
            self.searchAutocomplete(ui.item.value);
            return false;
          }
        })
        .autocomplete( "instance" )._renderItem = function( ul, item ) {
          return $( "<li>" )
            .append( "<div>" + item.label + "</div>" )
            .appendTo( ul );
        };

        $(".searchbar_validbt").on("click", function(){
            if($(".collection_searchbar").attr("data-searched") == "true"){
                self.closeSearch();
            }
        });

        $(".menu_toggler[data-toggler='toggle_photo']").on("click", function(){
            if(self.selectedTogglePhoto == "all") self.selectedTogglePhoto = "photo";
            else self.selectedTogglePhoto = "all";
            self.updateCollection();
        });

        $(".modal_closebt, .collection_section.fade").on("click", function(){
            console.log("close modal ???");
            App.selectedActeur = "n";
            console.log(App.selectedActeur);
            if(self.$Modal.hasClass("show")){
                self.$Modal.removeClass("show");
                $(".collection_section").removeClass("fade");
            }
            App.router.updateRoute();
        });

        this.$collectionItems.on("click", ".item_extbt", function(){
            self.displayActeurModal($(this).attr("data-acteur"));
            return false;
        });

        this.$Modal.on("click", ".network_acteur", function(){
            self.displayActeurModal($(this).attr("data-acteur"));
            return false;
        });

        this.$Modal.on("click", ".transac_prix, .objet_extbt", function(){
            var objetID = $(this).attr("data-objet");
            var venteID = $(this).attr("data-vente");
            location.href = "valeurs.php?#"+App.selectedLang+"/[]/[]/[]/[]/[]/["+objetID+"]/["+venteID+"]";
            return false;
        });

        this.$Modal.find(".modal_tab").on("click", function(){
            var tab = $(this).attr("data-tab");
            self.displayModalTab(tab);
            return false;
        });
    },

    updateSorting:function($sortbt){
        var sorting = $sortbt.attr("data-sorting");
        var sortBlock = $sortbt.attr("data-block");
        this.selectedSorting = sorting;
        this.selectedSortingBlock = sortBlock;
        this.updateCollection(true);
    },

    clearSorting:function($sortbt){
        this.selectedSortingBlock = "0";
        this.updateCollection();
    },

    cancelFilters:function(){
        this.selectedVentes = [];
        this.selectedTypes = [];
        this.selectedMatieres = [];
        this.selectedTogglePhoto = "all";
        this.selectedSortingBlock = "0";
        $(".block_sortbt").removeClass("selected");
        $(".menu_selectbox.expanded").removeClass("expanded");
        $(".collection_menu .menu_selectbox .selectbox_option").removeClass("selected");
        this.displayAllObjects();
        this.updateCollection();
    },

    selectOption:function($option, isNature){
        var $selectbox = $option.parents(".menu_selectbox");
        var selectboxData = $selectbox.attr("data-selectbox");
        var optionValue = $option.attr("data-value");
        if($option.hasClass("selected")){
            if(isNature){
                this.selectedNatures = _.without(this.selectedNatures, optionValue);
            }else if(selectboxData == "ventes"){
                this.selectedVentes = _.without(this.selectedVentes, optionValue);
            }else if(selectboxData == "types"){
                this.selectedTypes = _.without(this.selectedTypes, optionValue);
            }
            $option.removeClass("selected");
        }else{
            if(isNature){
                this.selectedNatures.push(optionValue);
            }else if(selectboxData == "ventes"){
                this.selectedVentes.push(optionValue);
            }else if(selectboxData == "types"){
                this.selectedTypes.push(optionValue);
            }
            $option.addClass("selected");
        }
        this.updateCollection();
    },

    selectNewOption:function($option){
        var $selectbox = $option.parents(".menu_selectbox");
        var selectboxData = $selectbox.attr("data-selectbox");
        var optionValue = $option.attr("data-value");

        $(".selectbox_option").removeClass("selected");
        if(selectboxData == "ventes"){
            this.selectedVentes.push(optionValue);
        }else if(selectboxData == "types"){
            this.selectedTypes.push(optionValue);
        }
        console.log("selectNewOption", JSON.stringify(this.selectedTypes));
        $option.addClass("selected");
        this.updateCollection();
    },

    searchArrayAutocomplete:function(arrayResults){
        console.log(arrayResults);
        var arrayObjetsIDS = _.map(arrayResults, function(ar){
            return ar.value;
        })
        App.filteredActeurs = _.filter(App.utils.acteurs, function(a){
            if(_.indexOf(arrayObjetsIDS, a.id) > -1 && a.type !== undefined){
                return true;
            }else{
                return false;
            }
        });
        $(".collection_searchbar").attr("data-searched", "true");
        this.displayAllObjects();
    },

    searchAutocomplete:function(objetID){
        App.filteredActeurs = _.filter(App.utils.acteurs, function(a){
            return a.id == objetID;
        });
        $(".collection_searchbar").attr("data-searched", "true");
        this.displayAllObjects();
    },

    closeSearch:function(){
        $( "#collectionSearchInput" ).val("");
        $(".collection_searchbar").attr("data-searched", "false");
        this.updateCollection();
    },

    displayActeurModal:function(acteurID){
        this.selectedActeur = acteurID;
        $(".modal_block[data-block='transactions']")[0].scrollTo(0,0);
        var acteurData = App.utils.acteurs[acteurID];

        this.$Modal.addClass("show")
                    .attr("data-type", acteurData.type);
        $(".collection_section").addClass("fade");
        $(".modal_title").html(acteurData.acteur_nom);
        $(".subtitle_extbt").attr("href", acteurData.acteur_url);
        $(".modal_block").empty();

        if(acteurData.type == "musee"){
            this.fillMuseeModal(acteurData);
        }else{
            this.fillActeurModal(acteurData);
        }

        this.router.updateRoute();
    },

    fillMuseeModal:function(acteurData){
        _.each(acteurData.objets, function(objetID){
            var objetData = App.utils.objets[objetID];
            $objet = $("<div class='musee_objet'><div class='objet_label'>"+objetData.nom_objet+"</div><div class='objet_extbt' data-objet='"+objetID+"'></div></div>");
            $(".modal_block[data-block='musee_objets']").append($objet);
        });
        this.displayModalTab("musee_objets");
    },

    fillActeurModal:function(acteurData){
        //achat/ventes
        var groupedTransactions = _.groupBy(acteurData.transactions, function(t){
            return t.vente_nom;
        });

        var sortedGroupedTransactions = _.sortBy(groupedTransactions, function(group){
            return group[0].vente_date_ts;
        });

        _.each(sortedGroupedTransactions, function(vente){
            console.log("alal vente", vente);
            var $vente = $("<div class='vente'><div class='vente_title'>"+vente[0].vente_nom+"</div><div class='vente_transactions'></div></div>");
            $(".modal_block[data-block='transactions']").append($vente);
            var sortedTransac = _.sortBy(vente, function(t){
                console.log(t.ventes_prix_value);
                prix = t.ventes_prix_value
                if(prix == null) prix = -1;
                return - prix;
            })
            _.each(sortedTransac, function(transac){
                var transacType = "vente";
                console.log("transac", transac);
                if(acteurData.id == transac.uk_acteur_vente_acheteur) transacType = "achat";
                var prixValue = _.str.numberFormat(transac.ventes_prix_value, 0, ".", " ");
                var prixUnit = "Francs";
                if(transac.ventes_prix_value == null){
                    prixValue = "";
                    prixUnit = App.utils.translations["prix-non-renseigne"][App.selectedLang];
                }
                var $transac = $("<div class='vente_transac'><div class='transac_objet'><a class='objet_label' href='story.php?#"+App.selectedLang+"/"+transac.id_objet+"'>"+App.utils.objets[transac.id_objet].nom_objet+"</a></div><div class='transac_prix' data-objet='"+transac.id_objet+"' data-prix='"+transac.ventes_prix_value+"' data-vente='"+transac.id_vente+"'><div class='prix_type' data-type='"+transacType+"'>"+App.utils.translations[transacType][App.selectedLang]+"</div><div class='prix_value'><div class='value_int'>"+prixValue+"</div><div class='value_unit'>"+prixUnit+"</div></div><a class='transac_extbt'></a><div class='prix_hoverblock'>"+App.utils.translations["comparer"][App.selectedLang]+"</div></div></div>");

                $vente.find(".vente_transactions").append($transac);
            })
        });

        //network
        $(".modal_block[data-block='network']").append("<div class='network'><div class='network_root'></div><div class='network_line'><div class='line_mask'></div></div></div>");

        var groupedNetwork = _.groupBy(acteurData.network, function(n){
            return n.type;
        });

        var tmpGroups = {
            group_acheteur:"",
            group_vendeur:"",
            "group_acheteur-vendeur":""
        };

        if(acteurData.imgExists){
            $(".network_root").css("background-image", "url('"+acteurData.img+"')")
                                .addClass("thumbed");
        }else{
            $(".network_root").css("background-image", "url('img/acteurs/acteurs-all-icon-reseau.svg')")
                                .removeClass("thumbed");
        }

        _.each(groupedNetwork, function(group, groupID){
            var $networkGroup = $("<div class='network_group' data-type='"+groupID+"'><div class='group_wrapper'><div class='group_title'>"+App.utils.translations[groupID][App.selectedLang]+"</div><div class='group_acteurs'></div></div></div>");
            console.log("group", group);
            var sortedGroup = _.sortBy(group, function(g){ return -g.nbObjets; });
            _.each(sortedGroup, function(acteurNetwork){
                var strObjet = App.utils.translations["objet"][App.selectedLang];;
                if(acteurNetwork.nbObjets > 1) strObjet = App.utils.translations["objets"][App.selectedLang];;
                var $networkActeur = $("<div class='network_acteur' data-acteur='"+acteurNetwork.id+"' data-type='"+acteurNetwork.type+"'><div class='acteur_nom'>"+App.utils.acteurs[acteurNetwork.id].acteur_nom+"</div><div class='acteur_objets'>"+acteurNetwork.nbObjets+" "+strObjet+"</div><div class='acteur_hoverblock'>"+App.utils.translations["voir-sa-fiche"][App.selectedLang]+"</div></div>");
                $networkGroup.find(".group_acteurs").append($networkActeur);
            });
            tmpGroups[groupID] = $networkGroup;
        });

        var lastNetworkHeight = 0;
        if(tmpGroups["group_vendeur"] !== ""){
            $(".modal_block[data-block='network']").find(".network").append(tmpGroups["group_vendeur"]);
        }
        if(tmpGroups["group_acheteur"] !== ""){
            $(".modal_block[data-block='network']").find(".network").append(tmpGroups["group_acheteur"]);
        }
        if(tmpGroups["group_acheteur-vendeur"] !== ""){
            $(".modal_block[data-block='network']").find(".network").append(tmpGroups["group_acheteur-vendeur"]);
        }



        this.displayModalTab("transactions");
    },

    displayModalTab:function(tabID){
        $(".modal_tab").removeClass("selected");
        $(".modal_tab[data-tab='"+tabID+"']").addClass("selected");

        $(".modal_block").removeClass("selected");
        $(".modal_block[data-block='"+tabID+"']").addClass("selected");
    }

};

module.exports = App;
window.App = App;