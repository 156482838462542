var StoryMapView = Backbone.View.extend({

    initialize:function(){
        var self = this;

        //viewport.w = 705 => zoom = 0.2
        //viewport.w = 2879 => zoom = 0.5

        //resoudre : x=3/21740 et y = 2233/21740

        this.resolveEquation = {
            x:3/21740,
            y:2233/21740
        }

        this.$CorollesOverlay = $("#CorollesOverlay");
        this.storyMap = {};
        this.snapOverlay = {};
        this.snapCircles = {};
        this.bezierTimeout = 1000;
        this.bezierYParam = 150;
        this.isSameLocation = false;

        this.revealedStep = "creation";
        this.revealedStepVente = "";
        this.isAnimated = {
            "decouverte":false,
            "ventes":false,
            "conservation":false
        };
    },

    render:function(){
        var self = this;
        this.createMap();
        this.createMapOverlay();
        setTimeout(function(){
            self.createCorolles();
        }, 10);
        setTimeout(function(){
            self.createHlitePoints();
        }, 100);
        this.bindEvents();
    },

    createMap:function(){
        var self = this;
        // Create the chart
        this.dataHighchartsPoints = _.filter(App.steps, function(s){ return s.lat !== ""; });
        this.storyMap = new Highcharts.mapChart({
            chart: {
                animation:false,
                map: 'custom/world-palestine-highres',
                renderTo: 'StoryMap',
                margin:[0,0,0,0],
                spacing:[0,0,0,0],
                backgroundColor:"#F7F3EB"
            },
            title: { text: '' },
            subtitle: { text: '' },
            credits:{ enabled:false },
            legend:{ enabled:false },
            mapNavigation: {
                enabled: false,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },
            tooltip:{
                enabled:false
            },
            plotOptions:{
                map:{
                    joinBy: ["iso-a2", "code"],
                    borderColor:"#bbb",
                    borderWidth:0,
                    nullColor:"#EEE5D0",
                    states:{
                        hover:{
                            enabled:false
                        }
                    }
                },
                mappoint:{
                    dataLabels:{
                        enabled:false
                    },
                    states:{
                        hover:{
                            enabled:false
                        }
                    }
                }
            },
            series: [
                {
                    type:"map",
                    name:'mapdata',
                    data:[
                        {code: "HU", value: 65, name: "Hungary", id: "HU", color: "#eee5d0"},
                        {code: "FR", value: 65, name: "France", id: "FR", color: "#eee5d0"},
                        {code: "US", value: 5, name: "USA", id: "US", color: "#eee5d0"}
                    ]
                },
                {
                    // Specify points using lat/lon
                    type: 'mappoint',
                    name: 'Cities',
                    data: self.dataHighchartsPoints
                }
            ]
        });

        this.centerLatLon = this.getCenterLatLon();
        this.centerPoint = this.storyMap.fromLatLonToPoint(this.centerLatLon);
        this.storyMap.mapZoom(this.viewportZoom, this.centerPoint.x, this.centerPoint.y);
        /*this.storyMap.get("HU").zoomTo();
        this.storyMap.mapZoom(10);*/

    },

    createMapOverlay:function(){
        var lastVente = App.stepsVentes.length;
        this.snapOverlay = Snap("#SnapOverlay");
        if(App.steps["vente_"+lastVente].location == App.steps["conservation"].location){
            $("#mapper").attr("data-samelocation", "true");
            this.isSameLocation = true;
        }
    },

    updateMap:function(){
        var self = this;
        var lastVente = App.stepsVentes.length;
        $(".hlite_point").removeClass("animated");
        console.log("updateMap", this.revealedStep, App.stepID);

        switch(this.revealedStep){
            case "creation":
                if(App.stepID == "creation"){
                    setTimeout(function(){
                        self.createCreationPoint();
                    }, 10);
                }else if(App.stepID == "decouverte"){
                    this.launchBezierAnimation(true, "creation", "decouverte", 100);
                }else if(App.stepID == "ventes"){
                    this.launchBezierAnimation(true, "creation", "decouverte", 100);
                    self.launchBezierAnimation(true, "decouverte", "vente_1", self.bezierTimeout);
                }else if(App.stepID == "conservation"){
                    this.launchBezierAnimation(true, "creation", "decouverte", 0);
                    self.launchBezierAnimation(true, "decouverte", "vente_1", self.bezierTimeout);
                    var bezierInc = 2;
                    if(App.stepsVentes.length > 1){
                        for(var k=1;k<App.stepsVentes.length;k++){
                            self.launchBezierAnimation(true, "vente_"+k, "vente_"+(k+1), self.bezierTimeout*bezierInc);
                            bezierInc += 1;
                        }
                    }        
                    self.launchBezierAnimation(true, "vente_"+lastVente, "conservation", self.bezierTimeout*bezierInc);
                }
                this.revealedStep = App.stepID;
            break;
            case "decouverte":
                if(App.stepID == "ventes"){
                    this.launchBezierAnimation(true, "decouverte", "vente_1", 0);
                    this.revealedStep = App.stepID;
                }else if(App.stepID == "conservation"){
                    this.launchBezierAnimation(true, "decouverte", "vente_1", 0);
                    self.launchBezierAnimation(true, "vente_"+lastVente, "conservation", self.bezierTimeout);
                    this.revealedStep = App.stepID;
                }else{
                    $(".hlite_point[data-step='"+App.stepID+"']").addClass("animated");
                }
            break;
            case "ventes":
                if(this.revealedStepVente == ""){
                    this.revealedStepVente = "vente_1";
                }

                //want to display next step vente
                var currentStepVenteInc = parseInt(App.stepVenteID.split("_")[1], 10);
                var revealedStepVenteInc = parseInt(this.revealedStepVente.split("_")[1], 10);

                if(currentStepVenteInc > revealedStepVenteInc){
                    this.launchBezierAnimation(true, "vente_"+revealedStepVenteInc, "vente_"+currentStepVenteInc, 0);
                }

                if(App.stepID == "conservation"){
                    this.launchBezierAnimation(true, "vente_"+lastVente, "conservation", 0);
                    this.revealedStep = App.stepID;
                }else{
                    $(".hlite_point[data-step='"+App.stepID+"']").addClass("animated");
                }
            break;
            case "conservation":
                /*if(App.steps["vente_"+lastVente].location == App.steps["conservation"].location){
                    this.highlightVente(App.steps["vente_"+lastVente].subventes.length);
                }*/
                $(".hlite_point[data-step='"+App.stepID+"']").addClass("animated");
            break;
        }

        if(App.stepID == "ventes"){
            this.highlightVente();
        }

        $("#mapper").attr("data-revealed", this.revealedStep);
    },

    highlightVente:function(forcedStepVenteInc){
        var stepVenteInc = 0;
        if(forcedStepVenteInc !== undefined) stepVenteInc = forcedStepVenteInc;
        else stepVenteInc = App.stepVenteInc;
        $(".corolle_vente").removeClass("selected");
        $(".corolle_vente[data-venteinc='"+stepVenteInc+"']").addClass("selected");
        $(".path_line[data-line='"+stepVenteInc+"']").attr("data-displayed", "true");
    },

    launchBezierAnimation:function(wantAnimation, location1ID, location2ID, timeout){
        var self = this;
        if(App.steps[location1ID].lat == "" || App.steps[location2ID].lat == ""){
            return false;
        }

        var myPathData = this.getPathFromTwoCities(location1ID, location2ID);
        var myPath = myPathData.path;

        var dataType = location1ID.split("_")[0];
        var myPathID = "myPath-"+location1ID;
        if(location2ID == "conservation"){
            dataType = "conservation";
            myPathID = "myPath-conservation";
            if(App.steps[location1ID].location == App.steps["conservation"].location){
                //this.highlightVente(App.steps[location1ID].subventes.length);
                $("#mapper").attr("data-samelocation", "true");
                //return false;
            }
        }

        var c = this.snapOverlay.path({
            d:myPath,
            id:myPathID,
            dataType:dataType,
            dataReverse:myPathData.reverse
        });

        if(location2ID == "conservation"){
            setTimeout(function(){
                self.createConservationPoint();
            }, 10);
        }
        if(wantAnimation){
            var totalLength = $("#"+myPathID)[0].getTotalLength();
            $("#"+myPathID).css("stroke-dasharray", totalLength+"px")
                        .css("stroke-dashoffset", totalLength+"px");

            setTimeout(function(){
                $("#"+myPathID).attr("class", "animate");
            }, timeout);
        }
    },

    getPathFromTwoCities:function(step1, step2){
        var isReverse = false;

        this.snapCircles[step1] = {};
        this.snapCircles[step1].position = this.getPointPositionFromStep(step1);
        this.snapCircles[step2] = {};
        this.snapCircles[step2].position = this.getPointPositionFromStep(step2);

        if(this.snapCircles[step1].position.x < this.snapCircles[step2].position.x) isReverse = true;

        var bezierPoint1 = {};
        var bezierPoint2 = {};
        var distanceX = this.snapCircles[step1].position.x - this.snapCircles[step2].position.x;

        var maxY = this.snapCircles[step1].position.y;
        if(maxY > this.snapCircles[step2].position.y) maxY = this.snapCircles[step2].position.y;

        if(distanceX<0){
            bezierPoint1 = {
                x:this.snapCircles[step1].position.x + (Math.abs(distanceX) / 3),
                y:maxY - this.bezierYParam
            };
            bezierPoint2 = {
                x:this.snapCircles[step1].position.x + (Math.abs(distanceX) * 0.666),
                y:maxY - this.bezierYParam
            };
        }else{
            bezierPoint1 = {
                x:this.snapCircles[step1].position.x - (Math.abs(distanceX) / 3),
                y:maxY - this.bezierYParam
            };
            bezierPoint2 = {
                x:this.snapCircles[step1].position.x - (Math.abs(distanceX) * 0.666),
                y:maxY - this.bezierYParam
            };
        }

        //return "M" + snapCircles[city1].position.x + " " + snapCircles[city1].position.y + "L" + snapCircles[city2].position.x + " " + snapCircles[city2].position.y;

        return {
            path:"M" + this.snapCircles[step1].position.x + " " + this.snapCircles[step1].position.y + "C" + bezierPoint1.x + " " + bezierPoint1.y + ", " +  bezierPoint2.x + " " + bezierPoint2.y + ", " + this.snapCircles[step2].position.x + " " + this.snapCircles[step2].position.y,
            reverse:isReverse
        };
    },

    createCorolles:function(){
        var self = this;
        var incVente = 0;
        _.each(App.stepsVentes, function(s, indexStep){
            if(s.corolle){
                var refPoint = self.getPointPositionFromStep(s.id);
                var $corolle = $("<div class='corolle' data-stepvente='vente_"+(indexStep+1)+"'></div>");
                var currentIndex = 0;
                _.each(s.subventes, function(subvente, index){
                    var $vente = $("<div class='corolle_vente' data-vente='"+(index+1)+"' data-venteinc='"+incVente+"'>#"+(index+1)+"</div>");
                    $corolle.append($vente);
                    incVente += 1;
                    currentIndex = (index+1);
                });

                //last corolle
                /*if(App.stepsVentes.length == (indexStep +1)){
                    //same city for last corolle and conservation
                    if(s.location == App.steps["conservation"].location){
                        var $conservation = $("<div class='corolle_vente' data-isconservation='true' data-venteinc='"+incVente+"' data-vente='"+(currentIndex+1)+"'></div>");
                        $corolle.append($conservation);
                    }
                }*/

                $corolle.css("left", refPoint.x)
                        .css("top", refPoint.y);
                $corolle.append('<svg class="corolle_svg"><g id="arcs" class="st7"><path id="arc8" class="path_line" d="M329.4,418.7c9.8-9.8,21.5-14.6,35.4-14.6" data-line="6"/><path id="arc7" class="path_line" d="M314.8,454.1c0-13.8,4.9-25.6,14.6-35.4" data-line="5"/><path id="arc6" class="path_line" d="M329.4,489.5c-9.8-9.8-14.6-21.5-14.6-35.4" data-line="4"/><path id="arc5" class="path_line" d="M364.8,504.1c-13.8,0-25.6-4.9-35.4-14.6" data-line="3"/><path id="arc4" class="path_line" d="M400.2,489.5c-9.8,9.8-21.5,14.6-35.4,14.6" data-line="2"/><path id="arc3" class="path_line" d="M414.8,454.1c0,13.8-4.9,25.6-14.6,35.4" data-line="1"/><path id="arc2" class="path_line" d="M400.2,418.7c9.8,9.8,14.6,21.5,14.6,35.4"/><path id="arc1" class="path_line" d="M364.8,404.1c13.8,0,25.6,4.9,35.4,14.6"/></g></svg>');
                self.$CorollesOverlay.append($corolle);

                _.each($(".corolle_svg .path_line"), function(path){
                    var totalLength = path.getTotalLength();
                    $(path).css("stroke-dasharray", totalLength+"px")
                        .css("stroke-dashoffset", totalLength+"px");
                });
            }else{
                incVente += 1;
            }
        });
    },

    createHlitePoints:function(){
        var self = this;
        var steps = ["creation", "decouverte", "ventes", "conservation"];
        _.each(steps, function(s){
            var step = s;
            if(s == "ventes") step = "vente_1";

            /*if(self.isSameLocation && s == "conservation"){
                var $conservationVente = $(".corolle_vente[data-isconservation='true']");
                refPoint = {
                    x:$conservationVente.offset().left - $("#StoryMap").offset().left,
                    y:$conservationVente.offset().top  - $("#StoryMap").offset().top
                };
            }else{
                var refPoint = self.getPointPositionFromStep(step);
            }*/
            var refPoint = self.getPointPositionFromStep(step);

            var $hlitePoint = $("<div class='hlite_point' data-step='"+s+"' data-location='"+App.steps[step].location+"'></div>");
            $hlitePoint.css("left", refPoint.x)
                            .css("top", refPoint.y);
            self.$CorollesOverlay.append($hlitePoint)
        });
        self.$CorollesOverlay.append("<div class='hlite_tooltip'></div>");
    },

    createCreationPoint:function(){
        var self = this;
        var refPoint = this.getPointPositionFromStep("creation");
        var $creationPoint = $("<div class='creation_point' data-type='"+_.str.slugify(App.utils.objets[App.objID].categorie_picto)+"'></div>");
        
        $creationPoint.css("left", refPoint.x)
                        .css("top", refPoint.y);        
        this.$CorollesOverlay.append($creationPoint)
        setTimeout(function(){
            self.$CorollesOverlay.find(".creation_point").addClass("scaled");
        }, 300);
    },

    createConservationPoint:function(){
        var self = this;
        var refPoint = this.getPointPositionFromStep("conservation");
        var $conservationPoint = $("<div class='conservation_point' data-location='"+App.steps.conservation.location+"'></div>");
        $conservationPoint.css("left", refPoint.x)
                        .css("top", refPoint.y);
        this.$CorollesOverlay.append($conservationPoint);
    },

    bindEvents:function(){
        $("#CorollesOverlay").on("mouseenter", ".hlite_point, .conservation_point", function(){
            var $point = $(this);
            var position = $point.position();
            $(".hlite_tooltip").html($point.attr("data-location"))
                                .addClass("displayed")
                                .attr("data-step", $point.attr("data-step"))
                                .css("left", position.left + $point.width()/2)
                                .css("top", position.top + $point.height());
        });

        $("#CorollesOverlay").on("mouseleave", ".hlite_point, .conservation_point", function(){
            $(".hlite_tooltip").removeClass("displayed");
        });

        $("#CorollesOverlay").on("click", ".hlite_point, .conservation_point", function(){
            var dataStep = $(this).attr("data-step");
            App.changeStep(dataStep);
        });

        $("#CorollesOverlay").on("click", ".corolle_vente", function(){
            var venteInc = parseInt($(this).attr("data-venteinc"), 10);
            App.stepVenteInc = venteInc;
            App.stepID = "ventes";
            App.update();
        });
    },

    getPointPositionFromStep:function(step){
        if($(".highcharts-point."+step).length > 0){
            return {
                x:($(".highcharts-point."+step).offset().left + ($(".highcharts-point."+step)[0].getBBox().width/2)) - $("#StoryMap").offset().left,
                y:($(".highcharts-point."+step).offset().top + ($(".highcharts-point."+step)[0].getBBox().height/2)) - $("#StoryMap").offset().top,
            };
        }else{
            return {
                x:null,
                y:null
            };
        }
    },

    getCenterLatLon:function(){
        var lat = {
            min:parseFloat(this.dataHighchartsPoints[0].lat),
            max:parseFloat(this.dataHighchartsPoints[0].lat)
        };

        var lon = {
            min:parseFloat(this.dataHighchartsPoints[0].lon),
            max:parseFloat(this.dataHighchartsPoints[0].lon)
        };

        _.each(App.steps, function(s){
            if(parseFloat(s.lat) < lat.min) lat.min = parseFloat(s.lat);
            if(parseFloat(s.lat) > lat.max) lat.max = parseFloat(s.lat);

            if(parseFloat(s.lon) < lon.min) lon.min = parseFloat(s.lon);
            if(parseFloat(s.lon) > lon.max) lon.max = parseFloat(s.lon);
        });

        this.minPXCenter = this.storyMap.fromLatLonToPoint({lat:lat.min, lon:lon.min});
        this.maxPXCenter = this.storyMap.fromLatLonToPoint({lat:lat.max, lon:lon.max});

        this.viewportSize = {
            w:this.maxPXCenter.x - this.minPXCenter.x,
            h:this.maxPXCenter.y - this.minPXCenter.y
        };

        var maxDimension = Math.abs(this.viewportSize.w);
        if(Math.abs(this.viewportSize.h) > maxDimension) maxDimension = Math.abs(this.viewportSize.h)
        this.viewportZoom = (maxDimension * this.resolveEquation.x) + this.resolveEquation.y;

        return {
            lat: (lat.max + lat.min) / 2,
            lon: (lon.max + lon.min) / 2
        }


    }

    /*
    zoomToCountry:function(countryCode){
        this.selectedCountry = countryCode;

        this.storyMap.get(this.selectedCountry).zoomTo();
        this.storyMap.mapZoom(10);
    },
    */
});

module.exports = StoryMapView;