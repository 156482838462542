var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromAll",
        ":lang":"routeFromAll",
        ":lang/:ventes":"routeFromAll",
        ":lang/:ventes/:types":"routeFromAll",
        ":lang/:ventes/:types/:natures":"routeFromAll",
        ":lang/:ventes/:types/:natures/:sorting":"routeFromAll",
        ":lang/:ventes/:types/:natures/:sorting/:order":"routeFromAll",
        ":lang/:ventes/:types/:natures/:sorting/:order/:acteur":"routeFromAll",
    },

    routeFromAll:function(lang, ventes, types, natures, sorting, order, acteur){
        (lang !== undefined && lang !== null && lang !== "") ? App.selectedLang = lang : App.selectedLang = "fr";
    
        if(ventes !== undefined && ventes !== null){
            var objVentes = JSON.parse(ventes);
            (objVentes.length > 0) ? App.selectedVentes = objVentes : App.selectedVentes = [];
        }

        if(types !== undefined && types !== null){
            var objTypes = JSON.parse(types);
            (objTypes.length > 0) ? App.selectedTypes = objTypes : App.selectedTypes = [];
        }

        if(natures !== undefined && natures !== null){
            var objNatures = JSON.parse(natures);
            (objNatures.length > 0) ? App.selectedNatures = objNatures : App.selectedNatures = [];
        }
        
        (sorting !== undefined && sorting !== null && sorting !== "") ? App.selectedSortingBlock = sorting : App.selectedSortingBlock = "nb_objets";

        (order !== undefined && order !== null && order !== "") ? App.selectedSorting = order : App.selectedSorting = "desc";

        (acteur !== undefined && acteur !== null && acteur !== "") ? App.selectedActeur = acteur : App.selectedActeur = "n";     
           
        if(!App.isLoaded){
            App.updateFromRouter();
        }else{
            if(ventes === null || ventes == undefined) location.href = document.referrer;
            else App.updateCollection();
        }
    },
    
    updateRoute:function(triggerize){
        this.navigate( App.selectedLang + "/" + JSON.stringify(App.selectedVentes) + "/" + JSON.stringify(App.selectedTypes) + "/" + JSON.stringify(App.selectedNatures) + "/" + App.selectedSortingBlock + "/" + App.selectedSorting + "/" + App.selectedActeur, {trigger: triggerize});
    },

});

module.exports = AppRouter;