var HeaderView = Backbone.View.extend({

    initialize:function(){
        var self = this;

    },

    render:function(){
        this.bindEvents();
    },

    bindEvents:function(){
        var self = this;
        $(".head_langbt").on("click", function(){
            var dataLang = $(this).attr("data-lang");
            console.log(dataLang);
            location.href = "#"+dataLang;
            location.reload();
        });

        $(".menu_bt").on("click", function(){
            var dataPage = $(this).attr("data-page");
            location.href = dataPage+".php#"+App.selectedLang;
        });

        $(".title_btn").on("click", function(){
            location.href="index.php#"+App.selectedLang;
        });
    },

});

module.exports = HeaderView;