var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.$Modal = $("#CollectionModale");
        this.$itemsWrapper = $(".collection_section[data-section='collection']");
        this.$collectionItems = $(".collection_items");
        this.selectedLang = "fr";

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("routerCollection");
        this.router = new Router();

        this.filteredObjects = [];

        this.selectedVentes = [];
        this.selectedTypes = [];
        this.selectedMatieres = [];

        this.selectedSortingBlock = "0";
        this.selectedSorting = "asc";
        this.selectedTogglePhoto = "all";

        this.nbCheckedImages = 0;
        this.ventesData = {};
        this.typesData = {};
        this.matieresData = {};

        this.autocompleteSource = [];

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        this.utils.gatherData(function(){
            self.render();
        });
    },

    render:function(){
        var self = this;
    	this.utils.fillTranslations();
    	this.headerView.render();
        this.footerView.render();
        $("#collectionSearchInput").attr("placeholder",App.utils.translations["rechercher-une-oeuvre"][App.selectedLang]);
        this.computeCollectionData(function(){
            self.initializeFilters();
            self.displayAllObjects();
            self.updateCollection(true);
            self.bindEvents();
        });
          
    },

    computeCollectionData:function(callback){
        var self = this;

        _.each(App.utils.objets, function(o){

            self.autocompleteSource.push({
                value:o.id_objet,
                label:o.nom_objet
            });

            if(o.classement_objet == "1") o.connaissances = 3;
            else{
                var nbConnaissances = 0;
                if(o.date_creation_debut !== App.utils.translations["information-manquante"][App.selectedLang]) nbConnaissances += 1;
                if(o.lieu_creation_nom !== App.utils.translations["information-manquante"][App.selectedLang]) nbConnaissances += 1;
                if(o.lieu_decouverte_nom !== App.utils.translations["information-manquante"][App.selectedLang]) nbConnaissances += 1;
                if(o.conservation_nom !== App.utils.translations["information-manquante"][App.selectedLang]) nbConnaissances += 1;
                if(_.size(o.ventes) > 0) nbConnaissances += 1;

                if(nbConnaissances == 5) o.connaissances = 3;
                else if(nbConnaissances >= 3) o.connaissances = 2;
                else o.connaissances = 1;
            }

            var lastVenteValue = null;
            _.each(o.sortedVentes, function(v){
                if(v.ventes_prix_value !== null){
                    lastVenteValue = v.ventes_prix_value;
                }
            });
            o.lastVenteValue = lastVenteValue;

            var objImg = "img/collection/UK_"+o.id_objet+".jpg";

            App.utils.imageExists(objImg, function(imgExists){
                o.img = objImg;
                o.imgExists = imgExists;
                self.nbCheckedImages += 1;
                if(self.nbCheckedImages == _.size(App.utils.objets)){
                    return callback();
                }
            });
        });
    },

    initializeFilters:function(){
        var self = this;

        var connaissancesTree = [false, false, false];
        this.uniqVentes = {};

        _.each(App.utils.objets, function(o){
            var slugType = _.str.slugify(o.categorie_picto);

            if(self.typesData[slugType] === undefined){
                self.typesData[slugType] = {
                    slug:slugType,
                    nom:o.categorie_picto
                };
                $(".menu_selectbox[data-selectbox='types'] .selectbox_options").append("<div class='selectbox_option' data-value='"+slugType+"'><div class='option_checkbox'></div><div class='option_label'>"+o.categorie_picto+"</div></option>");
            }

            if(o.matiere_filtre !== null){
                var slugMatiere = _.str.slugify(o.matiere_filtre);
                if(self.matieresData[slugMatiere] === undefined){
                    self.matieresData[slugMatiere] = {
                        slug:slugMatiere,
                        nom:o.matiere_filtre
                    };
                    $(".menu_selectbox[data-selectbox='matieres'] .selectbox_options").append("<div class='selectbox_option' data-value='"+slugMatiere+"'><div class='option_checkbox'></div><div class='option_label'>"+o.matiere_filtre+"</div></div>");
                }
            }

            _.each(o.ventes, function(v){
                var slugNomVente = _.str.slugify(v.vente_nom);
                if(self.ventesData[slugNomVente] === undefined){
                    self.ventesData[slugNomVente] = {
                        slug:slugNomVente,
                        vente_nom:v.vente_nom,
                        objets:[v.id_objet]
                    };
                    self.uniqVentes[slugNomVente] = {
                        slug:slugNomVente,
                        vente_nom:v.vente_nom,
                        timestamp:v.vente_date_ts
                    };
                }else{
                    if(_.indexOf(self.ventesData[slugNomVente].objets, v.id_objet) == -1){
                        self.ventesData[slugNomVente].objets.push(v.id_objet)
                    }
                }
            });

            connaissancesTree[o.connaissances-1] = true;
        });

        var sortedUniqVentes = _.sortBy(this.uniqVentes, function(v){ return v.timestamp; });
        _.each(sortedUniqVentes, function(sv){
            $(".menu_selectbox[data-selectbox='ventes'] .selectbox_options").append("<div class='selectbox_option' data-value='"+sv.slug+"'><div class='option_checkbox'></div><div class='option_label'>"+sv.vente_nom.split("Vente ")[1]+"</div></div>");
        });

        _.each(connaissancesTree, function(c, index){
            if(c == true) $(".menu_selectbox[data-selectbox='connaissances'] .selectbox_options").append("<div class='selectbox_option' data-value='"+(index+1)+"'><div class='option_checkbox'></div><div class='option_label'>"+(index+1)+" étoiles</div></div>");
        });

        $(".menu_toggler[data-toggler='toggle_photo']").attr("data-toggle", this.selectedTogglePhoto);
    },

    updateCollection:function(sorting){
        var self = this;
        this.isCancelable = false;
        this.filterCollection();
        this.updateMenu();

        if(this.selectedSortingBlock !== "0"){
            self.isCancelable = true;
            if(this.selectedSortingBlock == "creation"){
                App.filteredObjects = _.filter(App.filteredObjects, function(o){
                    return o.date_creation_debut_ts !== null;
                });
                if(this.selectedSorting == "asc"){
                    App.filteredObjects = _.sortBy(App.filteredObjects, function(o){
                        return o.date_creation_debut_ts;
                    });
                }else{
                    App.filteredObjects = _.sortBy(App.filteredObjects, function(o){
                        return -o.date_creation_debut_ts;
                    });
                }
            }else if(this.selectedSortingBlock == "valeur"){
                App.filteredObjects = _.filter(App.filteredObjects, function(o){
                    return o.lastVenteValue !== null;
                });
                if(this.selectedSorting == "asc"){
                    App.filteredObjects = _.sortBy(App.filteredObjects, function(o){
                        return o.lastVenteValue;
                    });
                }else{
                    App.filteredObjects = _.sortBy(App.filteredObjects, function(o){
                        return -o.lastVenteValue;
                    });
                }
            }else if(this.selectedSortingBlock == "connaissances"){
                if(this.selectedSorting == "asc"){
                    App.filteredObjects = _.sortBy(App.filteredObjects, function(o){
                        return o.connaissances;
                    });
                }else{
                    App.filteredObjects = _.sortBy(App.filteredObjects, function(o){
                        return -o.connaissances;
                    });
                }
            }
        }
        $(".collection_section[data-section='menu']").attr("data-cancelable", this.isCancelable);
        this.displayAllObjects();
        this.router.updateRoute();
    },

    filterCollection:function(){
        var self = this;

        this.filteredObjects = [];
        var neededRequired = 4;

        _.each(App.utils.objets, function(o){
            var required = 0;
            
            if(self.selectedVentes.length > 0){
                self.isCancelable = true;
                var objetVentesNoms = _.map(o.ventes, function(v){ return _.str.slugify(v.vente_nom); });
                if(_.intersection(self.selectedVentes, objetVentesNoms).length > 0){
                    required += 1;
                }else{

                }
            }else{
                required += 1;
            }

            if(self.selectedTypes.length > 0){
                self.isCancelable = true;
                var slugType = _.str.slugify(o.categorie_picto);
                if(_.indexOf(self.selectedTypes, slugType) > -1){
                    required += 1;
                }else{

                }
            }else{
                required += 1;
            }

            if(self.selectedMatieres.length > 0){
                self.isCancelable = true;
                var slugMatiere = _.str.slugify(o.matiere_filtre);
                if(_.indexOf(self.selectedMatieres, slugMatiere) > -1){
                    required += 1;
                }else{

                }
            }else{
                required += 1;
            }

            if(self.selectedTogglePhoto !== "all"){
                self.isCancelable = true;
                if(o.imgExists){
                    required += 1;
                }
            }else{
                required += 1;
            }

            if(required == neededRequired){
                self.filteredObjects.push(o);
            }

        });
    },

    displayAllObjects:function(sorting){
        var self = this;
        this.$collectionItems.empty();
        displayedObjects = App.filteredObjects;

        var sizeDisplayed = _.size(displayedObjects)
        $(".collection_title_nb").html(sizeDisplayed);
        if(sizeDisplayed <= 1){
            $(".collection_title_label").html(App.utils.translations["ouvre-antique"][App.selectedLang]);
        }else{
            $(".collection_title_label").html(App.utils.translations["ouvres-antiques"][App.selectedLang]);
        }

        var itemsToAppend = [];
        _.each(displayedObjects, function(o){
            var $item = $("<div class='collection_item' data-objet-id='"+o.id_objet+"'></div>");

            var $itemImage = "";

            if(App.nbCheckedImages == _.size(App.utils.objets) && !o.imgExists){
                var defaultImage = "img/collection/default/"+_.str.slugify(o.categorie_picto)+"-";
                if(o.matiere_filtre !== null && o.matiere_filtre !== ""){
                    defaultImage += (_.str.slugify(o.matiere_filtre) + ".svg");
                }else{
                    defaultImage += "defaut.svg";
                }
                $itemImage = $("<div class='item_image_container'><img class='item_image' src='"+defaultImage+"' /></div>");
            }else{
                $itemImage = $("<div class='item_image_container'><img class='item_image' src='img/collection/UK_"+o.id_objet+".jpg' /></div>");
            }

            var $itemIdentity = $("<div class='item_identity'><div class='identity_name'>"+_.str.prune(o.nom_objet, 50)+"</div></div>");

            var creationText = o.date_creation_string;
            var $itemCreation = "";
            if(creationText !== "") $itemCreation = $("<div class='item_creation'>"+creationText+"</div>");

            var strFrancs = "francs";
            if(o.lastVenteValue <= 1) strFrancs = "franc";
            var $itemValeur = $("<div class='item_valeur'>"+_.str.numberFormat(o.lastVenteValue, 0, ".", " ")+" "+strFrancs+"</div>");

            var $itemConnaissances = $("<div class='item_connaissances' data-connaissances='"+o.connaissances+"'><div class='connaissances_bar'><div class='bar_filling'></div><div class='bar_point' data-point='1'></div><div class='bar_point' data-point='2'></div><div class='bar_point' data-point='3'></div></div><div class='connaissances_label'>"+App.utils.translations["etat-des-connaissances"][App.selectedLang]+"</div></div>");

            var $itemExtbt = $("<a class='item_extbt' href='story.php#fr/"+o.id_objet+"/creation'><span class='item_extbt_label'>"+App.utils.translations["voir-son-histoire"][App.selectedLang]+"</span></a>");

            $item.append($itemImage)
                .append($itemIdentity)
                .append($itemCreation)
                .append($itemValeur)
                .append($itemConnaissances)
                .append($itemExtbt);
            self.$collectionItems.append($item);
        });
    },

    updateMenu:function(){
        var self = this;

        this.availableVentes = [];
        this.availableTypes = [];
        this.availableMatieres = [];
        this.availableConnaissances = [];

        $(".selectbox_option").removeClass("available");
        $(".block_sortbt").removeClass("selected");
        $(".sorting_block[data-block='"+App.selectedSortingBlock+"'] .block_sortbt[data-sorting='"+App.selectedSorting+"']").addClass("selected");

        _.each(App.filteredObjects, function(o){
            var objetVentesNoms = _.map(o.ventes, function(v){ return _.str.slugify(v.vente_nom); });
            _.each(objetVentesNoms, function(slugNomVente){
                if(_.indexOf(self.availableVentes, slugNomVente) == -1){
                    self.availableVentes.push(slugNomVente);
                    $(".menu_selectbox[data-selectbox='ventes'] .selectbox_option[data-value='"+slugNomVente+"']").addClass("available");
                }
            });

            var slugType = _.str.slugify(o.categorie_picto);
            if(_.indexOf(self.availableTypes, slugType) == -1){
                self.availableTypes.push(slugType);
                $(".menu_selectbox[data-selectbox='types'] .selectbox_option[data-value='"+slugType+"']").addClass("available");
            }

            var slugMatiere = _.str.slugify(o.matiere_filtre);
            if(_.indexOf(self.availableMatieres, slugMatiere) == -1){
                self.availableMatieres.push(slugMatiere);
                $(".menu_selectbox[data-selectbox='matieres'] .selectbox_option[data-value='"+slugMatiere+"']").addClass("available");
            }

            var stringConnaissances = String(o.connaissances);
            if(_.indexOf(self.availableConnaissances, stringConnaissances) == -1){
                self.availableConnaissances.push(stringConnaissances);
                $(".menu_selectbox[data-selectbox='connaissances'] .selectbox_option[data-value='"+stringConnaissances+"']").addClass("available");
            }
        });

        $(".menu_toggler[data-toggler='toggle_photo']").attr("data-toggle", self.selectedTogglePhoto);
    },

    bindEvents:function(){
    	var self = this;

        $(".menu_cancelbt").on("click", function(){
            self.cancelFilters();
        });

        $(".collection_menu .menu_selectbox").on("click", function(){
            var toExpand = false;
            if(!$(this).hasClass("expanded")) toExpand = true;
            $(".menu_selectbox.expanded").removeClass("expanded");
            if(toExpand) $(this).addClass("expanded");
            return false;
        });

        $(".collection_menu .menu_selectbox .selectbox_option").on("click", function(){
            if($(this).hasClass("available")){
                self.selectOption($(this));
            }else{
                self.selectNewOption($(this));
            }
            return false;
        });

        $(".collection_menu_sorting .block_sortbt").on("click", function(){
            if($(this).hasClass("selected")){
                self.clearSorting($(this));
            }else{
                self.updateSorting($(this));
            }
        });

        this.$App.on("click", function(){
            $(".menu_selectbox.expanded").removeClass("expanded");
        });

        $( "#collectionSearchInput" ).autocomplete({
          minLength: 0,
          source: self.autocompleteSource,
          response:function(event, ui){
            self.searchArrayAutocomplete(ui.content);
          },
          select: function( event, ui ) {
            $( "#collectionSearchInput" ).val(ui.item.label);
            self.searchAutocomplete(ui.item.value);
            return false;
          }
        })
        .autocomplete( "instance" )._renderItem = function( ul, item ) {
          return $( "<li>" )
            .append( "<div>" + item.label + "</div>" )
            .appendTo( ul );
        };

        $(".searchbar_validbt").on("click", function(){
            if($(".collection_searchbar").attr("data-searched") == "true"){
                self.closeSearch();
            }
        });

        $(".menu_toggler[data-toggler='toggle_photo']").on("click", function(){
            if(self.selectedTogglePhoto == "all") self.selectedTogglePhoto = "photo";
            else self.selectedTogglePhoto = "all";
            self.updateCollection();
        });

        $(".sorting_block[data-block='connaissances'] .block_label").on("click", function(){
            self.$Modal.addClass("show");
            $(".collection_section").addClass("fade");
            return false;
        });

        $(".modal_closebt, .collection_section.fade").on("click", function(){
            if(self.$Modal.hasClass("show")){
                self.$Modal.removeClass("show");
                $(".collection_section").removeClass("fade");
            }
        });
    },

    updateSorting:function($sortbt){
        var sorting = $sortbt.attr("data-sorting");
        var sortBlock = $sortbt.attr("data-block");
        this.selectedSorting = sorting;
        this.selectedSortingBlock = sortBlock;
        this.updateCollection(true);
    },

    clearSorting:function($sortbt){
        this.selectedSortingBlock = "0";
        this.updateCollection();
    },

    cancelFilters:function(){
        this.selectedVentes = [];
        this.selectedTypes = [];
        this.selectedMatieres = [];
        this.selectedTogglePhoto = "all";
        this.selectedSortingBlock = "0";
        $(".block_sortbt").removeClass("selected");
        $(".menu_selectbox.expanded").removeClass("expanded");
        $(".collection_menu .menu_selectbox .selectbox_option").removeClass("selected");
        this.displayAllObjects();
        this.updateCollection();
    },

    selectOption:function($option){
        var $selectbox = $option.parents(".menu_selectbox");
        var selectboxData = $selectbox.attr("data-selectbox");
        var optionValue = $option.attr("data-value");

        if($option.hasClass("selected")){
            if(selectboxData == "ventes"){
                this.selectedVentes = _.without(this.selectedVentes, optionValue);
            }else if(selectboxData == "types"){
                this.selectedTypes = _.without(this.selectedTypes, optionValue);
            }else if(selectboxData == "matieres"){
                this.selectedMatieres = _.without(this.selectedMatieres, optionValue);
            }
            $option.removeClass("selected");
        }else{
            if(selectboxData == "ventes"){
                this.selectedVentes.push(optionValue);
            }else if(selectboxData == "types"){
                this.selectedTypes.push(optionValue);
            }else if(selectboxData == "matieres"){
                this.selectedMatieres.push(optionValue);
            }
            $option.addClass("selected");
        }
        this.updateCollection();
    },

    selectNewOption:function($option){
        var $selectbox = $option.parents(".menu_selectbox");
        var selectboxData = $selectbox.attr("data-selectbox");
        var optionValue = $option.attr("data-value");

        this.selectedVentes = [];
        this.selectedTypes = [];
        this.selectedMatieres = [];

        $(".selectbox_option").removeClass("selected");
        if(selectboxData == "ventes"){
            this.selectedVentes.push(optionValue);
        }else if(selectboxData == "types"){
            this.selectedTypes.push(optionValue);
        }else if(selectboxData == "matieres"){
            this.selectedMatieres.push(optionValue);
        }
        $option.addClass("selected");
        this.updateCollection();
    },

    searchArrayAutocomplete:function(arrayResults){
        var arrayObjetsIDS = _.map(arrayResults, function(ar){
            return ar.value;
        })
        App.filteredObjects = _.filter(App.utils.objets, function(o){
            if(_.indexOf(arrayObjetsIDS, o.id_objet) > -1){
                return true;
            }else{
                return false;
            }
        });
        $(".collection_searchbar").attr("data-searched", "true");
        this.displayAllObjects();
    },

    searchAutocomplete:function(objetID){
        App.filteredObjects = _.filter(App.utils.objets, function(o){
            return o.id_objet == objetID;
        });
        $(".collection_searchbar").attr("data-searched", "true");
        this.displayAllObjects();
    },

    closeSearch:function(){
        $( "#collectionSearchInput" ).val("");
        $(".collection_searchbar").attr("data-searched", "false");
        this.updateCollection();
    }

};

module.exports = App;
window.App = App;