var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.$featuredItems = $(".featured_items");
        this.$SummaryModal = $("#SummaryModal");

        this.selectedLang = "fr";
        this.selectedItemId = "nola";

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("router");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        this.utils.gatherData(function(){
            self.utils.gatherParcoursSummaryData(function (){
                self.render();
            })
        });
    },

    render:function(){
    	this.utils.fillTranslations();
    	this.headerView.render();
        this.footerView.render();

        this.renderFeatured();
    	this.bindEvents();
    },

    renderFeatured: function () {
        var self = this;
        _.each(App.utils.parcoursFeaturedItems, function (obj){
            var $featuredItem = $("<div class='featured_item' data-parcoursid='"+obj.parcoursId+"'><div class='item_img'><img src='img/collection/"+obj.img+"' /></div><div class='item_title'>"+obj.title+"</div></div>");
            self.$featuredItems.append($featuredItem);
        })
    },

    bindEvents:function(){
    	var self = this;

        this.$featuredItems.on("click", ".featured_item", function () {
            var $item = $(this);
            self.selectedItemId = $item.attr("data-parcoursid");
            self.displayModal();
        });

        $("#SummaryModal .modal_bt").on("click", function () {
            var selectedObj = _.find(App.utils.parcoursFeaturedItems, function (o){ return o.parcoursId == self.selectedItemId});
            location.href = "parcours.php?id=" + selectedObj.parcoursId; 
        });
    },

    displayModal: function () {
        var self = this;
        var selectedObj = _.find(App.utils.parcoursFeaturedItems, function (o){ return o.parcoursId == self.selectedItemId});

        this.$SummaryModal.find(".modal_title").html(selectedObj.title);
        this.$SummaryModal.find(".modal_txtcontainer").html(selectedObj.text);
        this.$SummaryModal.find(".modal_illu .illu_img").empty();
        this.$SummaryModal.find(".modal_illu .illu_img").append("<img src='./img/collection/"+selectedObj.img+"' />");
        this.$SummaryModal.addClass("show");
        $("#ModalCache").addClass("displayed");
    }

};

module.exports = App;
window.App = App;