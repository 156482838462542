var StoryTimelineView = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.$timelineSeparatorContainer = $(".timelineSeparatorContainer");
    },

    render:function(){
        this.bindEvents();
        this.createMiniTimeline();
    },

    bindEvents:function(){

    },

    createMiniTimeline:function(){

        var self = this;
        var max = this.getMinMaxDate()[0];
        var min = this.getMinMaxDate()[1];
        $("#miniTimelineAfter").html(max);

        if(min<0){
            minFormat = Math.abs(min)+" "+App.utils.translations["av-jc"][App.selectedLang];
        } else{
            minFormat = min;
        }

        $("#miniTimelineBefore").html(minFormat);

        for(var i=0 ; i<500 ;i++){
            var $sep = $("<div class='timelineSeparator'></div>");
            this.$timelineSeparatorContainer.append($sep);
            $sep.css("left", ((i*2)-1)+"%");
        }

    },

    updateMiniTimeline:function(){
        var self = this;

        var selectedObj = App.utils.objets[App.objID];
        var max = this.getMinMaxDate()[0];
        var min = this.getMinMaxDate()[1];
        var totalTimeSpan = max+Math.abs(min);
        var yearInPixel = totalTimeSpan / $("#miniTimeline")[0].offsetWidth;
        var yearInIndicator = $("#miniTimelineIndicator")[0].offsetWidth / 2;
        $("#miniTimelineIndicator").removeClass("mask");

        switch(App.stepID){
            case("creation"):
                var annee = parseInt(selectedObj.date_creation_debut.replace(" ",""));
                $("#miniTimelineIndicator").attr("data-step","creation");
                break;
            case("decouverte"):
                var annee = parseInt(selectedObj.date_decouverte_debut.replace(" ",""));
                $("#miniTimelineIndicator").attr("data-step","decouverte");
                break;
            case("ventes"):
                var currentVente = App.ventesIDS[App.stepVenteInc];
                var venteDate = App.utils.ventes[currentVente].vente_date;
                var anneeRewrite = venteDate.replace(" ","");
                var annee = parseInt(venteDate.substr(anneeRewrite.length - 4));
                $("#miniTimelineIndicator").attr("data-step","ventes");
                break;
            case("conservation"):
                var annee = parseInt(selectedObj.conservation_date_entree.replace(" ",""));
                $("#miniTimelineIndicator").attr("data-step","conservation");
                break;
        }

        //old : Number.isInteger(annee)
       if(annee === parseInt(annee, 10)){

            if(annee<0){
                var left = parseInt(Math.abs(min - annee) / yearInPixel - yearInIndicator)+"px";
            }else{
                var left = parseInt(Math.abs(annee - min) / yearInPixel - yearInIndicator )+"px" 
            }

            var currentLeft = $("#miniTimelineIndicator").css("left");
            
            if(currentLeft != left){

                $("#miniTimelineIndicator").css("left",left);
                
                if(Math.abs(parseInt(currentLeft.substr(0, currentLeft.length - 2))-parseInt(left.substr(0, left.length - 2)))>25){
                    setTimeout(function(){
                        $("#miniTimelineIndicator").addClass("fat");
                        setTimeout(function(){
                            $("#miniTimelineIndicator").removeClass("fat");
                        },500)
                    },400)
                }
            }

       } else{
            $("#miniTimelineIndicator").addClass("mask");
       }
    },

    getMinMaxDate:function(){
        var self = this
        var objets = App.utils.objets;
        var ventes = App.utils.ventes;
        var dates = [];
        var datesCreation = [];
        var datesConservation = [];
        var datesVentes = [];
      
        _.each(objets,function(obj,i){
            var creation = parseInt(obj.date_creation_debut);
            if(!isNaN(creation)){
                datesCreation.push(creation)
            }
            var conservation = parseInt(obj.conservation_date_entree)
            if(!isNaN(conservation)){
                datesConservation.push(conservation)
            }
        });

        _.each(ventes,function(vente,i){
            var date = vente.vente_date.replace(/\s+/g, '').substr(vente.vente_date.replace(/\s+/g, '').length - 4);;
            var ventedate = parseInt(date)
            if(!isNaN(ventedate)){
                datesVentes.push(ventedate)
            }
        });

        var maxConservation = Math.max.apply(Math, datesConservation);
        var minCreation = Math.min.apply(Math, datesCreation);
        var maxVentes = Math.max.apply(Math, datesVentes);

        if(maxVentes > maxConservation){
            var maxDate = maxVentes;
        }else{
            var maxDate = maxConservation;
        }
        
        var minDate = minCreation;
        return([maxDate,minDate])
    },

    updateTimeline:function(){
        var self = this;
        var ventesReste = (App.ventesIDS.length-1)-App.stepVenteInc;
        if(App.stepID == "ventes"&&ventesReste>=0&&ventesReste!=App.ventesIDS.length-1&&App.ventesIDS.length!=1){
            this.changeVentesIndicator();
        }else{
            this.cleanTimeline();
        }
    },

    changeDataTimeline:function(){
        switch(App.stepID){
            case("creation"):
                this.createCreationTime();
                break;
            case("decouverte"):
                this.createDecouvereTime();
                break;
            case("ventes"):
                this.createVentesTime();
                break;
            case("conservation"):
                this.createConservationTime();
                break;
        }
    },

    cleanTimeline:function(){
        $("#timelineBigMarker").addClass("mask");
        $(".venteMarker").remove();
        $(".timelineRepere").html("");
        $("#timeline").removeClass("off");
        $("#nextStepBtn").removeClass("off");
        $("#timelineContainer").removeClass("off");
        setTimeout(function(){
            $("#miniTimelineContainer").removeClass("off");
            setTimeout(function(){
                $("#timelineBigMarker").removeClass("precise");
            },400)
        },400)
        this.animateTimeline();
    },

    hideTimeline:function(){
        $("#miniTimelineContainer").addClass("off");
        setTimeout(function(){
            $("#timelineBigMarker").addClass("mask");
            $("#timelineContainer").addClass("off");
            $("#timeline").addClass("off");
            $("#nextStepBtn").addClass("off");
        },200)
    },

    animateTimeline:function(){
        var self = this;
        $(".timelineRepere").addClass("mask");
        switch(App.stepID){
            case("creation"):
                this.transitionTimelineSeparator(0);
                break;
            case("decouverte"):
                this.transitionTimelineSeparator(-20);
                break;
            case("ventes"):
                this.transitionTimelineSeparator(-40);
                break;
            case("conservation"):
                this.transitionTimelineSeparator(-60);
                break;
        }
        
        setTimeout(function(){
            self.changeDataTimeline()
            $(".timelineRepere").removeClass("mask");
        },1000)
    },

    transitionTimelineSeparator:function(decalPct){
        this.$timelineSeparatorContainer.css("left", decalPct+"%");
    },

    createCreationTime:function(){
        var self=this;
        var debut = parseInt(App.utils.objets[App.objID].date_creation_debut);
        var fin = parseInt(App.utils.objets[App.objID].date_creation_fin);
        this.setBorneFromCentralDates(debut,fin);
        if(debut==fin) $("#timelineBigMarker").addClass("precise");
        $("#timelineBigMarker").removeClass("mask").attr("data-step","creation");
    },

    createDecouvereTime:function(){
        var self=this;
        var debut = parseInt(App.utils.objets[App.objID].date_decouverte_debut.replace(" ",""));
        var fin = parseInt(App.utils.objets[App.objID].date_decouverte_fin.replace(" ",""));
        if(App.utils.objets[App.objID].date_decouverte_debut==""&&App.utils.objets[App.objID].date_decouverte_fin==""){
            this.hideTimeline();
        } else{
            this.setBorneFromCentralDates(debut,fin);
            if(debut==fin) $("#timelineBigMarker").addClass("precise");
            $("#timelineBigMarker").removeClass("mask").attr("data-step","decouverte");      
        }
    },

    createVentesTime:function(){
        var self=this;
        var debutString = App.utils.ventes[App.ventesIDS[0]].vente_date;
        var debut = parseInt(debutString.replace(/\s+/g, '').substr(debutString.replace(/\s+/g, '').length - 4));
        var finString = App.utils.ventes[App.ventesIDS[App.ventesIDS.length-1]].vente_date;
        var fin = parseInt(finString.replace(/\s+/g, '').substr(finString.replace(/\s+/g, '').length - 4));
        ecart = fin-debut;
        if(ecart == 0) ecart=2;
        var firstBorne = parseInt(debut-(ecart/2));
        var lastBorne = parseInt(fin+(ecart/2));
        milieu = (lastBorne + firstBorne)/2;
        var joursentrelesdeuxbornes = (lastBorne - firstBorne)*365;
        var pixelsInJours = ($("#timeline").width()*0.8)/joursentrelesdeuxbornes;

        $("#middleTimelineRepere").html(milieu);
        $("#beforeTimelineRepere").html(firstBorne);
        $("#afterTimelineRepere").html(lastBorne);
        $("#justbeforeTimelineRepere").html("");
        $("#justafterTimelineRepere").html("");
        $("#timelineBigMarker").removeClass("show");

        prevLeft = 0;

        _.each(App.ventesIDS,function(vente,i){
            var parseDateVente = self.parseDate($.trim(App.utils.ventes[vente].vente_date));
            var parseDebut = self.parseDate("1 janvier "+firstBorne);
            var nbDays = self.daydiff(parseDebut,parseDateVente);
            var daysInPx = nbDays * pixelsInJours;

            var left = ($("#timeline").width()*0.1) + daysInPx;
            if(daysInPx-prevLeft<10){
                $("#timeline").append('<div class="venteMarker clear" data-ventemarker='+i+' style=left:'+left+'px></div>')
            }else{
                $("#timeline").append('<div class="venteMarker" data-ventemarker='+i+' style=left:'+left+'px></div>')    
            }
            prevLeft=daysInPx;
        });

        setTimeout(function(){
            $(".venteMarker").addClass("show");
            $(".venteMarker[data-ventemarker='0']").addClass("active");
        },250)
    },

    createConservationTime:function(){
        var self = this;
        var date = parseInt(App.utils.objets[App.objID].conservation_date_entree);

        if(App.utils.objets[App.objID].conservation_date_entree==""){
            this.hideTimeline();
        } else{
            this.setBorneFromCentralDates(date,date);
            $("#timelineBigMarker").addClass("precise");
            $("#timelineBigMarker").removeClass("mask").attr("data-step","conservation");;      
        }
    },

    changeVentesIndicator:function(){
        $(".venteMarker").removeClass("active");
        var inc = App.stepVenteInc;
        $(".venteMarker[data-ventemarker='"+inc+"']").addClass("active");
    },

    parseDate:function(str){
        self = this;
        var mois=0;
        var mdy = str.split(' ');

        switch(mdy[1]){
            case("janvier"):
                mois=01;
                break;
            case("février"):
                mois=02;
                break;
            case("mars"):
                mois=03;
                break;
            case("avril"):
                mois=04;
                break;
            case("mai"):
                mois=05;
                break;
        }
        return new Date(mdy[2], mois-1, mdy[0]);
    },

    daydiff:function(first,second){
        var self=this;
        return Math.round((second-first)/(1000*60*60*24));
    },


    setBorneFromCentralDates:function(debut,fin){
        if(debut==fin){
            var milieu = debut;
            var justBefore = debut - 10;
            var firstBorne = debut - 20;
            var justAfter = fin+10;
            var lastBorne = fin+20;
        } else{
            if(debut<0 && fin<0){
                var milieu = parseInt(debut+(fin+Math.abs(debut))/2);
                var justBefore = parseInt(debut-(fin+Math.abs(debut))/2);
                var firstBorne = parseInt(debut-(fin+Math.abs(debut))*1.5);
                var justAfter = parseInt(fin+(fin+Math.abs(debut))/2);
                var lastBorne = parseInt(fin+(fin+Math.abs(debut))*1.5);
            } else if(debut>0 && fin>0){
                var milieu = parseInt(debut+(fin-Math.abs(debut))/2);
                var justBefore = parseInt(debut-(fin-Math.abs(debut))/2);
                var firstBorne = parseInt(debut-(fin-Math.abs(debut))*1.5);
                var justAfter = parseInt(fin+(fin-Math.abs(debut))/2);
                var lastBorne = parseInt(fin+(fin-Math.abs(debut))*1.5);
            } else{
                var milieu = parseInt((fin-Math.abs(debut))/2);
                var justBefore = parseInt(debut-(fin/2));
                var firstBorne = parseInt(debut-(fin*2));
                var justAfter = parseInt(fin+(fin/2));
                var lastBorne = parseInt(fin+(fin*2));
            }
        }

        $("#beforeTimelineRepere").html(firstBorne);
        $("#justbeforeTimelineRepere").html(justBefore);
        $("#middleTimelineRepere").html(milieu);
        $("#afterTimelineRepere").html(lastBorne);
        $("#justafterTimelineRepere").html(justAfter);
        return({milieu:milieu,justBefore:justBefore,firstBorne:firstBorne,justAfter:justAfter,lastBorne:lastBorne});;
    },

});

module.exports = StoryTimelineView;