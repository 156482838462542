var ParcoursTutoView = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.$ParcoursTuto = $("#ParcoursTuto");
        this.$TutoIlluCopy = $("#TutoIlluCopy");
        this.tutoSeen = null;
        this.currentStep = 1;

        this.stepIlluSelectorParams = {
            2:{
                selector: ".sidebar_selectbox[data-item='types']"
            },
            3:{
                selector: ".sidebar_selectbox[data-item='matieres']"
            },
            4:{
                selector: ".sidebar_locationbox"
            },
            5:{
                selector: ".sidebar_selectbox[data-item='ventes']"
            },
            6:{
                selector: ".sidebar_selectbox[data-item='acteurs']"
            },
            7:{
                selector: "#ParcoursTimeline"
            },
            8:{
                selector: ".sidebar_bucket"
            }
        }
    },

    render:function(){
        var self = this;

        //this.tutoSeen = localStorage.getItem("inha-parcours");
        this.bindEvents();
    },

    bindEvents:function(){
        var self = this;

        $(".step_nextbt").on("click", function () {
            self.gotoNextStep($(this));
        });

        $(".step_explorebt").on("click", function () {
            self.finishTuto();
        });

        $(".step_cancelbt").on("click", function () {
            self.tutoSeen = true;
            App.cancelTuto = true;
            App.isFirstUpdate = true;
            App.selectedItems = JSON.parse(JSON.stringify(App.defaultSelectedItems));
            self.closeTuto();
            App.update("sidebar");
        })

        $("#ParcoursTutobt").on("click", function (){
            self.tutoSeen = null;
            self.currentStep = 1;
            App.isFirstUpdate = true;
            self.update();
        });
    },

    update: function () {

        if(this.tutoSeen == null && !App.forceFeatured) {
            $(".tuto_step").removeClass("displayed");
            this.$TutoIlluCopy.removeClass("displayed")
                        .empty();
            this.$ParcoursTuto.addClass("displayed");
            var $tutoStep = $(".tuto_step[data-step='"+this.currentStep+"']");
            
            $tutoStep.addClass("displayed");

            if(this.stepIlluSelectorParams[this.currentStep] !== undefined) {
                this.$TutoIlluCopy.addClass("displayed")
                                .attr("data-step", this.currentStep);

                var $clonable = $(this.stepIlluSelectorParams[this.currentStep].selector);
                $clonable.clone().appendTo("#TutoIlluCopy");

                this.$TutoIlluCopy.css("left", $clonable.offset().left)
                        .css("top", $clonable.offset().top);

                $tutoStep.css("top", $clonable.offset().top);
            }
        } else {
            this.closeTuto();
        }
    },

    gotoNextStep: function ($stepBt) {
        var $stepBlock = $stepBt.parents(".tuto_step");
        var stepNb = $stepBlock.attr("data-step");
        this.currentStep = parseInt(stepNb, 10) + 1;

        this.update();

        if(stepNb == "1") {
            App.selectedItems.types = ["vase"];
            App.parcoursSidebarView.update();
        } else if(stepNb == "2") {
            App.selectedItems.matieres = ["terre cuite"];
            App.parcoursSidebarView.update();
        } else if(stepNb == "3") {
            App.selectedItems.locations.toutes = ["Attique"];
            App.parcoursSidebarView.update();
        } else if(stepNb == "4") {
            App.selectedItems.ventes = ["Vente Canino 1837"];
            App.parcoursSidebarView.update();
        } else if(stepNb == "5") {
            App.selectedItems.acteurs = ["Beugnot, Gustave Adolphe (1799 - 1861)"];
            App.parcoursSidebarView.update();
        }
    },

    closeTuto: function () {
        this.$ParcoursTuto.removeClass("displayed");
        this.$TutoIlluCopy.removeClass("displayed")
                        .empty();
        $(".tuto_step").removeClass("displayed");
    },

    finishTuto: function () {
        localStorage.setItem("inha-parcours", "true");
        this.tutoSeen = true;
        this.closeTuto();
        App.isFirstUpdate = true;
        App.update()
    }
});

module.exports = ParcoursTutoView;