var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromAll",
        ":lang":"routeFromAll",
        ":lang/:ventes":"routeFromAll",
        ":lang/:ventes/:types":"routeFromAll",
        ":lang/:ventes/:types/:matieres":"routeFromAll",
        ":lang/:ventes/:types/:matieres/:photo":"routeFromAll",
        ":lang/:ventes/:types/:matieres/:photo/":"routeFromAll",
        ":lang/:ventes/:types/:matieres/:photo/:sorting":"routeFromAll",
        ":lang/:ventes/:types/:matieres/:photo/:sorting/:order":"routeFromAll",
    },

    routeFromAll:function(lang, ventes, types, matieres, photo, sorting, order){
        console.log("routeFromAll");
        (lang !== undefined && lang !== null && lang !== "") ? App.selectedLang = lang : App.selectedLang = "fr";
        
        if(ventes !== undefined && ventes !== null){
            var objVentes = JSON.parse(ventes);
            (objVentes.length > 0) ? App.selectedVentes = objVentes : App.selectedVentes = [];
        }
        
        if(types !== undefined && types !== null){
            var objTypes = JSON.parse(types);
            (objTypes.length > 0) ? App.selectedTypes = objTypes : App.selectedTypes = [];
        }

        if(matieres !== undefined && matieres !== null){
            var objMatieres = JSON.parse(matieres);
            (objMatieres.length > 0) ? App.selectedMatieres = objMatieres : App.selectedMatieress = [];
        }

        (photo !== undefined && photo !== null && photo !== "") ? App.selectedTogglePhoto = photo : App.selectedTogglePhoto = "all";
        (sorting !== undefined && sorting !== null && sorting !== "") ? App.selectedSortingBlock = sorting : App.selectedSortingBlock = "connaissances";
        (order !== undefined && order !== null && order !== "") ? App.selectedSorting = order : App.selectedSorting = "desc";        

        App.updateFromRouter();
    },
    
    updateRoute:function(triggerize){
        this.navigate( App.selectedLang + "/" + JSON.stringify(App.selectedVentes) + "/" + JSON.stringify(App.selectedTypes) + "/" + JSON.stringify(App.selectedMatieres) + "/" + App.selectedTogglePhoto + "/" + App.selectedSortingBlock + "/" + App.selectedSorting, {trigger: triggerize});
    },

});

module.exports = AppRouter;