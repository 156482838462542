var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        console.log("init ?");
    },

};

module.exports = App;
window.App = App;