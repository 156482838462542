var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromURL",
        ":lang":"routeFromLang",
        ":scenario/:emissions/:year/:criteria":"routeWoLang",
    },

    routeFromURL:function(params){ 
        App.updateFromRouter();
    },

    routeFromLang:function(lang){
      App.selectedLang = lang;
      App.updateFromRouter();
    },

    routeWoLang:function(scenario, emissions, year, criteria){
      this.routeFromParams("en", scenario, emissions, year, criteria);
    },
    
    updateRoute:function(triggerize){
        var weightArray = _.map(App.utils.criteriaWeightValues, function(weight){
            return weight;
        });

        this.navigate( App.selectedLang + "/" + App.sidebarView.scenarioValue + "/" + App.sidebarView.emissionOption + "/" + App.selectedYear + "/" + JSON.stringify(weightArray), {trigger: triggerize});
    },

});

module.exports = AppRouter;