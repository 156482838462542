var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromAll",
        ":lang":"routeFromAll",
        ":lang/:ventes":"routeFromAll",
        ":lang/:ventes/:types":"routeFromAll",
        ":lang/:ventes/:types/:vasetypes":"routeFromAll",
        ":lang/:ventes/:types/:vasetypes/:matieres":"routeFromAll",
        ":lang/:ventes/:types/:vasetypes/:matieres/:acteurs":"routeFromAll",
        ":lang/:ventes/:types/:vasetypes/:matieres/:acteurs/:idobjet":"routeFromAll",
        ":lang/:ventes/:types/:vasetypes/:matieres/:acteurs/:idobjet/:idvente":"routeFromAll",
        ":lang/:ventes/:types/:vasetypes/:matieres/:acteurs/:idobjet/:idvente/":"routeFromAll",
    },

    routeFromAll:function(lang, ventes, types, vasetypes, matieres, acteurs, idobjet, idvente){
        console.log("routeFromAll");
        (lang !== undefined && lang !== null && lang !== "") ? App.selectedLang = lang : App.selectedLang = "fr";
        
        if(ventes !== undefined && ventes !== null){
            var objVentes = JSON.parse(ventes);
            App.selectedParams.ventes = objVentes;
        }
        
        if(types !== undefined && types !== null){
            var objTypes = JSON.parse(types)
            App.selectedParams.types = objTypes;
        }

        if(vasetypes !== undefined && types !== null){
            var objVasetypes = JSON.parse(vasetypes)
            App.selectedParams.vasetypes = objVasetypes;
        }

        if(matieres !== undefined && matieres !== null){
            var objMatieres = JSON.parse(matieres)
            App.selectedParams.matieres = objMatieres;
        }

        if(acteurs !== undefined && acteurs !== null){
            var objActeurs = JSON.parse(acteurs)
            App.selectedParams.acteurs = objActeurs;
        }

        if(idobjet !== undefined && idobjet !== null){
            var objIdobjet = JSON.parse(idobjet)
            App.selectedParams.idobjet = objIdobjet;
        }

        if(idvente !== undefined && idvente !== null){
            var objIdvente = JSON.parse(idvente);
            App.selectedParams.idvente = objIdvente;
        }

        App.updateFromRouter();
    },
    
    updateRoute:function(triggerize){
        console.log("updateRoute", App.selectedLang + "/" + JSON.stringify(App.selectedParams.ventes) + "/" + JSON.stringify(App.selectedParams.types) + "/"+ JSON.stringify(App.selectedParams.vasetypes) + "/"+ JSON.stringify(App.selectedParams.matieres) + "/"+ JSON.stringify(App.selectedParams.acteurs) + "/"+ JSON.stringify(App.selectedParams.idobjet) + "/" +  JSON.stringify(App.selectedParams.idvente));
        this.navigate( App.selectedLang + "/" + JSON.stringify(App.selectedParams.ventes) + "/" + JSON.stringify(App.selectedParams.types) + "/"+ JSON.stringify(App.selectedParams.vasetypes) + "/"+ JSON.stringify(App.selectedParams.matieres) + "/"+ JSON.stringify(App.selectedParams.acteurs) + "/"+ JSON.stringify(App.selectedParams.idobjet) + "/" +  JSON.stringify(App.selectedParams.idvente), {trigger: triggerize});
    },

});

module.exports = AppRouter;