var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromURL",
        ":lang":"routeFromLang",
        ":lang/:objid":"routeFromLangObj",
        ":lang/:objid/":"routeFromLangObj",
        ":lang/:objid/:stepid":"routeFromAll",
        ":lang/:objid/:stepid/":"routeFromAll",
    },

    routeFromURL:function(params){ 
        App.objID = App.utils.getRandomStarId();
        App.stepID = "creation";
        App.updateFromRouter();
    },

    routeFromLang:function(lang){
        App.selectedLang = lang;
        App.objID = App.utils.getRandomStarId();
        App.stepID = "creation";
        App.updateFromRouter();
    },

    routeFromLangObj:function(lang, objID){
        App.selectedLang = lang;
        App.objID = objID;
        App.stepID = "creation";
        App.updateFromRouter();
    },

    routeFromAll:function(lang, objID, stepID){
        App.selectedLang = lang;
        App.objID = objID;
        App.stepID = stepID;
        App.updateFromRouter();
    },
    
    updateRoute:function(triggerize){
        this.navigate( App.selectedLang + "/" + App.objID + "/" + App.stepID, {trigger: triggerize});
    },

});

module.exports = AppRouter;