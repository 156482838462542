var ParcoursSidebarView = Backbone.View.extend({

    initialize:function(){
        var self = this;
        this.allItems = {
            "types":[],
            "matieres": [],
            "ventes": ["toutes"],
            "acteurs": ["tous"]
        }

        this.$Sidebar = $("#ParcoursSidebar");
        this.$sidbarTogglebt = $("#SidebarTogglebt");
        this.$selectorTypes = $(".sidebar_selectbox[data-item='types'] .selectbox_selector");
        this.$selectorMatieres = $(".sidebar_selectbox[data-item='matieres'] .selectbox_selector");
        this.$selectorVentes = $(".sidebar_selectbox[data-item='ventes'] .selectbox_selector .selector_items");
        this.$selectorActeurs = $(".sidebar_selectbox[data-item='acteurs'] .selectbox_selector .selector_items");
        this.$cancelFiltersbt = $(".cancel_filtersbt");

        this.$sidebarLocationbox = $(".sidebar_locationbox");
        this.$sidebarBucket = $(".sidebar_bucket");
        this.$bucketList = $(".bucket_list");
        this.$bucketSavebt = $(".bucket_savebt");

        this.isMasked = false;
        this.uniqLocations = {
            "toutes":[],
            "creation":[],
            "decouverte":[],
            "conservation":[],
            "ventes":[]
        };
    },

    render:function(){
        var self = this;
        this.$sidebarLocationbox.attr("data-allmode", App.allLocationsMode);
        this.renderSelectors();
        this.bindEvents();
    },

    renderSelectors: function () {
        var self = this;

        _.each(App.objetsWithLocation, function (o){
            if(self.allItems.types.indexOf(o.type_objet) == -1) {
                self.allItems.types.push(o.type_objet);
            }

            var strMatiere = o.matiere_filtre;
            if(strMatiere == null) strMatiere = "autre";
            if(self.allItems.matieres.indexOf(strMatiere) == -1) {
                self.allItems.matieres.push(strMatiere);
            }
        })
        self.allItems.types = _.sortBy(self.allItems.types, function (o){ return o.normalize("NFD").replace(/[\u0300-\u036f]/g, "");});
        self.allItems.types.unshift("tous");
        self.allItems.matieres = _.sortBy(self.allItems.matieres, function (o){ return o.normalize("NFD").replace(/[\u0300-\u036f]/g, "");});
        self.allItems.matieres.unshift("toutes");
        self.allItems.matieres = _.without(self.allItems.matieres, "autre");
        self.allItems.matieres.push("autre");

        var sortedVentes = _.sortBy(App.uniqVentes, function (arr, venteNom){
            return arr[0].vente_date_ts
        });
        _.each(sortedVentes, function (arr, index){
            self.allItems.ventes.push(arr[0].vente_nom);
        })


        _.each(App.uniqActeurs, function (objActeur){
            self.allItems.acteurs.push(objActeur.acteur_nom);
        })
        self.allItems.acteurs = _.sortBy(self.allItems.acteurs, function (o){ return o.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); });

        _.each(self.allItems.types, function (itemString) {
            var $selectorItem = $("<div class='selector_item' data-item='"+itemString+"'><div class='item_checkbox'></div><div class='item_text'>"+itemString+" <span class='item_nb'></span></div></div>");
            self.$selectorTypes.append($selectorItem);
        })

        _.each(self.allItems.matieres, function (itemString) {
            var $selectorItem = $("<div class='selector_item' data-item='"+itemString+"'><div class='item_checkbox'></div><div class='item_text'>"+itemString+"<span class='item_nb'></span></div></div>");
            self.$selectorMatieres.append($selectorItem);
        })

        _.each(self.allItems.ventes, function (itemString) {
            if(itemString !== "toutes"){
                var $selectorItem = $("<div class='selector_item' data-item='"+itemString+"'><div class='item_checkbox'></div><div class='item_text'>"+itemString+"<span class='item_nb'></span></div></div>");
                self.$selectorVentes.append($selectorItem);
            }
        })

        _.each(self.allItems.acteurs, function (itemString) {
            if(itemString !== "tous"){
                var $selectorItem = $("<div class='selector_item' data-item='"+itemString+"'><div class='item_checkbox'></div><div class='item_text'>"+itemString+"<span class='item_nb'></span></div></div>");
                self.$selectorActeurs.append($selectorItem);
            }
        })
    },

    update: function () {
        var self = this;

        $(".selector_item").removeClass("checked");
        this.$bucketList.empty();

        var nbItems = 0;

        _.each(App.selectedItems, function (arr, index){
            if(index == "types" || index == "matieres" || index == "ventes" || index == "acteurs") {
                var $sidebarSelectbox = $(".sidebar_selectbox[data-item='"+index+"']");

                var nbSelected = arr.length;
                var strCardinal = "";

                $sidebarSelectbox.removeClass("activated");
                if(arr.length == 1 && (arr[0] == "tous" || arr[0] == "toutes")){
                    nbSelected = self.allItems[index].length - 1;
                    if(index == "types" || index == "acteurs") strCardinal = "tous";
                    else strCardinal = "toutes";
                } else {
                    $sidebarSelectbox.addClass("activated");
                    strCardinal = nbSelected +"/"+ (self.allItems[index].length - 1);
                }

                $sidebarSelectbox.find(".selectbox_head").html(index + " <span class='head_cardinal'>("+ strCardinal +")</span>");
                _.each(arr, function (strItem){
                    var $selectorItem = $sidebarSelectbox.find(".selector_item[data-item='"+strItem+"']");
                    $selectorItem.addClass("checked");
                })

                if(arr.length > 0) {
                    var rowStr = "Types";
                    if(index == "matieres") rowStr = "Matières";
                    else if(index == "ventes") rowStr = "Ventes";
                    else if(index == "acteurs") rowStr = "Acteurs";
                    var $listRow = $("<div class='list_row'><div class='row_label'>"+rowStr+"&nbsp;:</div><div class='row_items'></div></div>");
                    _.each(arr, function (strItem){
                        if(strItem !== "tous" && strItem !== "toutes") {
                            var $rowItem = $("<div class='row_item' data-itemtype='"+index+"' data-value='"+strItem+"'>"+strItem+" x</div>");
                            $listRow.find(".row_items").append($rowItem);
                        }
                    })

                    if(arr.length == 1 && (arr[0] == "toutes" || arr[0] == "tous")){

                    } else {
                        nbItems += 1;
                        self.$bucketList.append($listRow);    
                    }
                }
            }
            else if(index == "locations") {
                var flattenLocations = [];
                _.each(arr, function (arrayLocations, indexLocation){
                    var $locationInputbox = $(".location_inputbox[data-location='"+indexLocation+"']")
                    $locationInputbox.removeClass("selected");
                    $locationInputbox.find(".selector_items").empty();
                    if(arrayLocations.length > 0) $locationInputbox.addClass("selected");

                    _.each(arrayLocations, function (locaValue){
                        flattenLocations.push({
                            value: locaValue,
                            index: indexLocation
                        });

                        var locaValueDisplay = locaValue;
                        if(locaValue.length > 30) locaValueDisplay = _.str.truncate(locaValue, 30);

                        var $locationChipitem = $("<div class='location_chipitem' data-value='"+locaValue+"'>"+locaValueDisplay+" x</div>");
                        $locationInputbox.find(".selector_items").append($locationChipitem);
                    })
                })

                if(flattenLocations.length > 0) {
                    var rowStr = "Lieux";
                    var $listRow = $("<div class='list_row'><div class='row_label'>"+rowStr+"&nbsp;:</div><div class='row_items'></div></div>");
                    _.each(flattenLocations, function (obj){
                        var $rowItem = $("<div class='row_item' data-itemtype='"+index+"' data-locatype='"+obj.index+"' data-value='"+obj.value+"'>"+obj.value+" x</div>");
                        $listRow.find(".row_items").append($rowItem);
                    })

                    nbItems += 1;
                    self.$bucketList.append($listRow);
                }
            }
        });

        var allLocations = _.union(App.availableLocations.conservation, App.availableLocations.decouverte, App.availableLocations.creation);
        this.uniqLocations["toutes"] = App.utils.getUniqArrayObjFromKey(allLocations, "value");
        this.uniqLocations["conservation"] = App.utils.getUniqArrayObjFromKey(App.availableLocations.conservation, "value");
        this.uniqLocations["decouverte"] = App.utils.getUniqArrayObjFromKey(App.availableLocations.decouverte, "value");
        this.uniqLocations["creation"] = App.utils.getUniqArrayObjFromKey(App.availableLocations.creation, "value");

        //timeline bucket
        _.each(App.parcoursTimelineView.sliderParams, function (o, tabId){
            if(!isNaN(o.min) && o.values[0] !== null && (o.values[0] != o.min || o.values[1] != o.max)){
                var rowStr = "Dates de découverte";
                if(tabId == "creation") rowStr = "Dates de création";
                else if(tabId == "vente") rowStr = "Dates de vente";
                var $listRow = $("<div class='list_row'><div class='row_label'>"+rowStr+"&nbsp;:</div><div class='row_items'><div class='row_item'>de "+o.values[0]+" à "+o.values[1]+" x</div></div></div>");
                self.$bucketList.append($listRow);
                nbItems += 1;
            }
        })
        

        this.$sidebarBucket.attr("data-nbitems", nbItems);

        this.updateItemsAvailability();
    },

    updateItemsAvailability: function () {
        var availabilityObj = {
            "type":{param: "type_objet", filtering: ["matiere", "location", "vente", "acteur"] },
            "matiere":{ param: "matiere_filtre", filtering:["type", "location", "vente", "acteur"] },
            "vente":{ param: "", filtering:["matiere", "location", "type", "acteur"] },
            "acteur":{ param: "", filtering:["matiere", "location", "vente", "type"] }
        }

        $(".item_nb").html("(0)");

        _.each(availabilityObj, function (avaObj, id){
            var availableObjects = _.filter(App.objetsWithLocation, function (o){
                return o.filtering[avaObj.filtering[0]] && o.filtering[avaObj.filtering[1]] && o.filtering[avaObj.filtering[2]] && o.filtering[avaObj.filtering[3]]
            });

            var availableItems = []
            if(id == "type") {

                availableItems = _.map(availableObjects, function (ao) { return ao.type_objet; })
                availableItems = _.uniq(availableItems);

                $(".selectbox_selector[data-item='types'] .selector_item").attr("data-available", "false");
                _.each(availableItems, function (itemLabel) {
                    var availabledNbItems = _.filter(availableObjects, function (ao) { return ao.type_objet == itemLabel });
                    var $selector = $(".selectbox_selector[data-item='types'] .selector_item[data-item='"+itemLabel+"']");
                    $selector.attr("data-available", "true");
                    $selector.find(".item_nb").html("("+availabledNbItems.length+")");
                })

            } else if (id == "matiere") {
                availableItems = _.map(availableObjects, function (ao) { return ao.matiere_filtre; })
                availableItems = _.uniq(availableItems);
                $(".selectbox_selector[data-item='matieres'] .selector_item").attr("data-available", "false");
                _.each(availableItems, function (itemLabel) {
                    var availabledNbItems = _.filter(availableObjects, function (ao) { return ao.matiere_filtre == itemLabel });
                    var $selector = $(".selectbox_selector[data-item='matieres'] .selector_item[data-item='"+itemLabel+"']");
                    $selector.attr("data-available", "true");
                    $selector.find(".item_nb").html("("+availabledNbItems.length+")");
                })
            } else if (id == "vente") {
                var availableObjectsIds = _.map(availableObjects, function (ao) { return ao.id_objet });
                var availableVentes = _.filter(App.utils.ventes, function (objVente){
                    return availableObjectsIds.indexOf(objVente.id_objet) > -1;
                })
                var availableVentesNoms = _.map(availableVentes, function (av){ return av.vente_nom; });
                availableVentesNoms = _.uniq(availableVentesNoms);

                $(".selectbox_selector[data-item='ventes'] .selector_item").attr("data-available", "false");
                _.each(availableVentesNoms, function (venteNom) {
                    var availabledNbItems = _.filter(availableVentes, function (ao) { return ao.vente_nom == venteNom });
                    var $selector = $(".selectbox_selector[data-item='ventes'] .selector_item[data-item='"+venteNom+"']");
                    $selector.attr("data-available", "true");
                    $selector.find(".item_nb").html("("+availabledNbItems.length+")");

                    //$(".selectbox_selector[data-item='ventes'] .selector_item[data-item='"+venteNom+"']").attr("data-available", "true");
                })
            } else if (id == "acteur") {
                var availableObjectsIds = _.map(availableObjects, function (ao) { return ao.id_objet });
                var availableActeurs = _.filter(App.uniqActeurs, function (objActeur){
                    var intersect = _.intersection(objActeur.objets, availableObjectsIds);
                    objActeur.nbAvailableObj = intersect.length
                    return intersect.length > 0;
                })

                //var availableActeursNoms = _.map(availableActeurs, function (aa) { return aa.acteur_nom; });

                $(".selectbox_selector[data-item='acteurs'] .selector_item").attr("data-available", "false");
                _.each(availableActeurs, function (acteurObj) {
                    var $selector = $(".selectbox_selector[data-item='acteurs'] .selector_item[data-item='"+acteurObj.acteur_nom+"']");
                    $selector.attr("data-available", "true");
                    $selector.find(".item_nb").html("("+acteurObj.nbAvailableObj+")");

                    //$(".selectbox_selector[data-item='acteurs'] .selector_item[data-item='"+acteurObj.acteur_nom+"']").attr("data-available", "true");
                })
            }
            
        })
    },

    bindEvents:function(){
        var self = this;

        $("#App").on("click", function (){
            self.closeAllSelectboxes();
        })
        
        this.$sidbarTogglebt.on("click", function (){
            self.toggleSidebar();
        });

        $(".title_infosbt").on("click", function () {
            self.displayMethodoModal();
        });

        $(".inputbox_selector").on("click", function (evt){
            evt.stopImmediatePropagation();
        })

        // Selectboxes Types/Matieres
        $("#ParcoursSidebar .selectbox_head").on("click", function (){
            var selectboxItem = $(this).attr("data-item");
            self.toggleSelectbox(selectboxItem);
            return false;
        })

        this.$Sidebar.on("click", ".selector_item", function (){
            var $item = $(this);
            var $parent = $item.parents(".sidebar_selectbox");
            var selectboxItem = $parent.attr("data-item");
            var strItem = $item.attr("data-item");
            self.toggleSelectorItem(strItem, selectboxItem, $item.attr("data-available"));
            return false;
        });

        this.$Sidebar.on("click", ".selectbox_selector", function () {
            return false;
        })

        // Inputs location
        $(".toggle_toggler").on("click", function (){
            App.allLocationsMode = !App.allLocationsMode;
            self.$sidebarLocationbox.attr("data-allmode", App.allLocationsMode);
        })

        $(".location_inputbox input").on("focus keyup", function (evt){
            self.closeAllSelectboxes();
            self.updateAutocompleteResults($(this));
            return false;
        })

        $(".location_inputbox .inputbox_icon").on("click", function (){
            $(".location_inputbox").removeClass("expanded");
        });

        $(".inputbox_autocompleteresults").on("click", ".autocompleteresults_item", function (){
            self.toggleLocationItem($(this));
        })

        $(".inputbox_selector").on("click", ".location_chipitem", function (){
            self.removeLocationItem($(this));
        })

        //Selectboxes Ventes/Acteurs
        $(".sidebar_selectbox .searchblock_input").on("click", function (evt){
            return false;
        })
        $(".sidebar_selectbox[data-item='ventes'] .searchblock_input").on("keyup", function (evt){
            self.updateVentesAutocomplete($(this));
            return false;
        })

        $(".sidebar_selectbox[data-item='acteurs'] .searchblock_input").on("keyup", function (evt){
            self.updateActeursAutocomplete($(this));
            return false;
        })

        //Bucket
        $(".bucket_list").on("click", ".row_item", function (){
            self.removeBucketItem($(this));
        })

        this.$cancelFiltersbt.on("click", function (){
            self.removeAllBucketItems();
        })

        this.$bucketSavebt.on("click", function () {
            self.displaySaveModal();
        })
    },

    toggleSelectbox: function (selectboxItem) {
        $(".location_inputbox").removeClass("expanded");
        var $selectbox = $(".sidebar_selectbox[data-item='"+selectboxItem+"']");

        if($selectbox.hasClass("expanded")){
            $(".sidebar_selectbox").removeClass("expanded");
            $(".searchblock_input").val("");
        } else {
            $(".sidebar_selectbox").removeClass("expanded");
            $selectbox.addClass("expanded");
        }
    },

    closeAllSelectboxes: function () {
        $(".sidebar_selectbox").removeClass("expanded");
        $(".searchblock_input").val("");
        $(".location_inputbox").removeClass("expanded");
    },

    toggleSelectorItem: function (strItem, selectboxItem, available) {
        if(strItem == "tous" || strItem == "toutes") {
            App.selectedItems[selectboxItem] =[strItem];
        } else {
            if(available == "false") {
                App.selectedItems = JSON.parse(JSON.stringify(App.defaultSelectedItems));
                App.selectedItems[selectboxItem] = _.without(App.selectedItems[selectboxItem], "tous", "toutes")
                App.selectedItems[selectboxItem].push(strItem);
            } else {
                if(App.selectedItems[selectboxItem].indexOf(strItem) == -1) {
                    App.selectedItems[selectboxItem] = _.without(App.selectedItems[selectboxItem], "tous", "toutes")
                    App.selectedItems[selectboxItem].push(strItem);
                } else {
                    App.selectedItems[selectboxItem] = _.without(App.selectedItems[selectboxItem], strItem);
                }
            }
        }
        App.update("sidebar");
    },

    updateAutocompleteResults: function ($input) {
        var inputVal = $input.val();
        var dataLocation = $input.attr("data-location");

        var matchingLocations = [];

        if(inputVal == ""){
            matchingLocations = this.uniqLocations[dataLocation];
        } else {
            _.each(this.uniqLocations[dataLocation], function (o){
                if(o.value.toLowerCase().includes(inputVal.toLowerCase())) {
                    matchingLocations.push(o);
                }  
            });
        }

        var $inputbox = $(".location_inputbox[data-location='"+dataLocation+"']");
        $inputbox.addClass("expanded");

        $inputbox.find(".inputbox_autocompleteresults").html("");

        var sortedLocations = _.sortBy(matchingLocations, function (o){
            return (o.label).normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        })

        var noresultsObj = _.find(sortedLocations, function (o){
            return o.label == "Information manquante";
        })

        _.each(sortedLocations, function (o){
            if(o.label !== "Information manquante") {
                var $resultItem = $("<div class='autocompleteresults_item' data-value='"+o.value+"'>"+o.label+" <span class='item_nb'>("+o.nb+")<span></div>");
                if(App.selectedItems.locations[dataLocation].indexOf(o.value) > -1) $resultItem.addClass("selected");
                $inputbox.find(".inputbox_autocompleteresults").append($resultItem);
            }
        })

        if(noresultsObj !== undefined) {
            var $resultItem = $("<div class='autocompleteresults_item' data-value='"+noresultsObj.value+"'>"+noresultsObj.label+" <span class='item_nb'>("+noresultsObj.nb+")<span></div>");
            if(App.selectedItems.locations[dataLocation].indexOf(noresultsObj.value) > -1) $resultItem.addClass("selected");
            $inputbox.find(".inputbox_autocompleteresults").append($resultItem);
        }
    },

    toggleLocationItem: function ($locationItem){
        var dataLocation = $locationItem.parent().attr("data-location");
        var locationValue = $locationItem.attr("data-value");
        if(App.selectedItems.locations[dataLocation].indexOf(locationValue) == -1) {
            App.selectedItems.locations[dataLocation].push(locationValue);
        } else {
            App.selectedItems.locations[dataLocation] = _.without(App.selectedItems.locations[dataLocation], locationValue);
        }

        if(dataLocation == "toutes") {
            App.selectedItems.locations["creation"] = [];
            App.selectedItems.locations["decouverte"] = [];
            App.selectedItems.locations["conservation"] = [];
        } else {
            App.selectedItems.locations["toutes"] = [];
        }

        var $inputbox = $(".location_inputbox[data-location='"+dataLocation+"']");
        $inputbox.removeClass("expanded");

        $inputbox.find(".inputbox_input").val("");

        App.update("sidebar");
    },

    removeLocationItem: function ($locationChipitem) {
        var $locationInputbox = $locationChipitem.parents(".location_inputbox");
        var dataLocation = $locationInputbox.attr("data-location");
        var locationValue = $locationChipitem.attr("data-value");
        App.selectedItems.locations[dataLocation] = _.without(App.selectedItems.locations[dataLocation], locationValue);
        App.update("sidebar");
    },

    //Ventes
    updateVentesAutocomplete: function ($input) {
        var inputVal = $input.val();


        var matchingVentes = [];
        _.each(App.uniqVentes, function (o, venteNom){
            if(venteNom.toLowerCase().includes(inputVal.toLowerCase())) {
                matchingVentes.push(venteNom);
            }  
        });

        $(".selectbox_selector[data-item='ventes'] .selector_item").addClass("masked");
        _.each(matchingVentes, function (venteNom){
            $(".selectbox_selector[data-item='ventes'] .selector_item[data-item='"+venteNom+"']").removeClass("masked");
        })
    },

    //Acteurs
    updateActeursAutocomplete: function ($input){
        var inputVal = $input.val();

        var matchingActeurs = [];
        _.each(App.uniqActeurs, function (o){
            if( o.acteur_nom.toLowerCase().includes(inputVal.toLowerCase())) {
                matchingActeurs.push(o.acteur_nom);
            }  
        });

        $(".selectbox_selector[data-item='acteurs'] .selector_item").addClass("masked");
        _.each(matchingActeurs, function (acteurNom){
            $('.selectbox_selector[data-item="acteurs"] .selector_item[data-item="'+acteurNom+'"]').removeClass('masked');
        })
    },

    toggleSidebar: function () {
        var self = this;
        this.isMasked = !this.isMasked;
        this.$Sidebar.attr("data-masked", this.isMasked);
        App.$App.attr("data-sidebarmasked", this.isMasked);

        if(this.isMasked) {
            this.$sidbarTogglebt.find("span").html("Afficher")
        } else {
            this.$sidbarTogglebt.find("span").html("Masquer")
        }

        setTimeout(function (){
            App.reloadMap()
        }, 10)
    },

    //Bucket
    removeBucketItem: function ($item) {
        var itemType = $item.attr("data-itemtype");
        if(itemType == "locations") {
            var locaType = $item.attr("data-locatype");
            var locaValue = $item.attr("data-value");
            App.selectedItems.locations[locaType] = _.without(App.selectedItems.locations[locaType], locaValue);
        } else {
            var itemValue = $item.attr("data-value");
            App.selectedItems[itemType] = _.without(App.selectedItems[itemType], itemValue);
        }
        App.update("sidebar");
    },

    removeAllBucketItems: function () {
        App.selectedItems = JSON.parse(JSON.stringify(App.defaultSelectedItems));
        App.update("sidebar");
    },

    displaySaveModal: function () {
        $("#SaveModal").addClass("show");
        App.generateParcoursSaveURL();
    },

    displayMethodoModal: function () {
        $("#MethodoModal").addClass("show");
    }

});

module.exports = ParcoursSidebarView;